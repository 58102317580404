import React from 'react';
import styles from "./HobbyPage.module.scss";
import { lightOrDark } from '../../../shared/Utils';
const logo = require('../../../shared/grain.png');
const sheep = require('../../../shared/sheep.jpg');
const running = require('../../../shared/running.jpg');
const lisbon = require('../../../shared/lisbon.jpg');
const walk = require('../../../shared/walk.jpg');
const cascais = require('../../../shared/cascais.jpg');
const ameland = require('../../../shared/ameland.jpg');
const BeachPoleDance = require('../../../shared/BeachPoleDance.jpg');
const hangmat = require('../../../shared/hangmat.jpg');

const runningS = require('../../../shared/small/running.jpg');
const sheepS = require('../../../shared/small/sheep.jpg');
const lisbonS = require('../../../shared/small/lisbon.jpg');
const walkS = require('../../../shared/small/walk.jpg');
const cascaisS = require('../../../shared/small/cascais.jpg');
const amelandS = require('../../../shared/small/ameland.jpg');
const BeachPoleDanceS = require('../../../shared/small/BeachPoleDance.jpg');
const hangmatS = require('../../../shared/small/hangmat.jpg');

class HobbyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        }
        this.setActive = this.setActive.bind(this)
    }

    componentDidMount() {
        var mut = new MutationObserver(this.setActive);
        mut.observe(document.querySelector("#hobbies"), {
            'attributes': true
        });
    }

    componentDidUpdate() {
        // console.log("Hobby page rerendered")
    }

    setActive(mutations, mut) {
        this.setState({ ...this.state, isActive: !this.isActive })
    }

    render() {
        return (
            <div className={styles.mobileMainContainer} style={{ borderRadius: `10px`, backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)' }}>
                <div className={styles.mobileContainer} style={{
                    display: 'flex', padding: '10px', border: '20px solid #0000', outline: '1px solid' + lightOrDark(this.props.sideBarColor), outlineOffset: '-10px',
                    background: `conic-gradient(from 90deg at 1px 1px, #0000 25%,${lightOrDark(this.props.sideBarColor)} 0)`, justifyContent: `center`
                }}>
                    <div className={styles.cardhobbycontainer + " " + styles.mobile}>
                        <div key={this.props.isActive} className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? sheep.default : sheepS.default}')`, "--angle": '-5deg', "--x": '5%', "--y": '15%', "--caption": "'Much wool much wow'" }}></div>
                        <div key={this.props.isActive} className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? running.default : runningS.default}')`, "--angle": '-1deg', "--x": '-10%', "--y": '-20%', "--caption": "'Running Contest 12km'" }}></div>
                        <div key={this.props.isActive} className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? lisbon.default : lisbonS.default}')`, "--angle": '-4deg', "--x": '-20%', "--y": '5%', "--caption": "'Lisboa 2023'" }}></div>
                        <div key={this.props.isActive} className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? walk.default : walkS.default}')`, "--angle": '7deg', "--x": '10%', "--y": '-7%', "--caption": "'Bare Feet Path 2022'" }}></div>
                    </div >

                    <div className={styles.cardhobbycontainer + " " + styles.mobile}>
                        <div className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? cascais.default : cascaisS.default}')`, "--angle": '-5deg', "--x": '5%', "--y": '15%', "--caption": "'Cascais 2023'" }}></div>
                        <div className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? ameland.default : amelandS.default}')`, "--angle": '-1deg', "--x": '-10%', "--y": '-20%', "--caption": "'Ameland 2022'" }}></div>
                        <div className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? BeachPoleDance.default : BeachPoleDanceS.default}')`, "--angle": '-4deg', "--x": '-20%', "--y": '5%', "--caption": "'Ameland 2019'" }}></div>
                        <div className={styles.cardhobby} style={{ backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', "--image": `url('${this.state.isActive ? hangmat.default : hangmatS.default}')`, "--angle": '7deg', "--x": '10%', "--y": '-7%', "--caption": "'Park angerenstein 2022'" }}></div>
                    </div >
                </div>

                <style>
                    {`--hobby-color: ${lightOrDark(this.props.sideBarColor)};
            `}
                </style>
            </div >
        )
    }
}

export default HobbyPage;