import React from 'react';
import ColorPreferencesTab from "./ColorPreferences"
import BackgroundPreferencesTab from "./BackgroundPreferences"
import ContentTab from "./ContentTab"
import ThemesTab from "./Themes"
import styles from "./PreferencesTab.module.css"
import HomePage from '../HomePage/HomePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'

class PreferencesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarColor: this.props.sideBarColor ? this.props.sideBarColor : "#2c3e50",
      sideBarColor2: this.props.sideBarColor2 ? this.props.sideBarColor2 : "#000000",
      selectedImage: this.props.selectedImage ? this.props.selectedImage : 'default',
      shownPages: this.props.shownPages ? this.props.shownPages : '',
      saveButton: true,
      activeTab: "-1",
      isOpen: true
    };
  }

  setSideBarColorWithoutEvent = (color) => {
    this.setState({
      ...this.state,
      sideBarColor: color
    }, () => this.setActive());
  }

  setSideBarColor2WithoutEvent = (color) => {
    this.setState({
      ...this.state,
      sideBarColor2: color
    }, () => this.setActive());
  }

  setSideBarColor = (event) => {
    event.preventDefault()
    this.setSideBarColorWithoutEvent(event.target.value);
  }

  setSideBarColor2 = (event) => {
    event.preventDefault()
    this.setSideBarColor2WithoutEvent(event.target.value)
  }

  setTheme = (color, color2, image) => {
    this.setState({
      ...this.state,
      sideBarColor: color,
      sideBarColor2: color2,
      selectedImage: image
    }, () => this.setActive());
  }

  setActive = () => {
    this.setState({ ...this.state, saveButton: false });
  }

  selectImage = (name) => {
    this.setState({ ...this.state, selectedImage: name }, () => { this.setActive() });
  }

  setWebsiteView = (shownPages) => {
    this.setState({ ...this.state, shownPages: shownPages }, () => { this.setActive() })
  }

  render() {
    return (
      <div style={{
        display: `flex`,
        flexDirection: `column`,
        height: `100vh`
      }
      }>
        <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ marginTop: `5px`, justifyContent: `center`, border: 0 }}>
          <li className="nav-item mx-1" role="presentation">
            <button className={"nav-link " + styles.navlink + " " + (this.state.activeTab == "-1" ? "active" : "")} style={{ backgroundColor: this.props.sideBarColor + '99', color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "-1" })}>
              <i className={"fa fa-door-open"} ></i> <br /> Welcome
            </button>
          </li>
          <li className="nav-item mx-1" role="presentation">
            <button className={"nav-link " + styles.navlink + " " + (this.state.activeTab == "0" ? "active" : "")} style={{ backgroundColor: this.props.sideBarColor + '99', color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "0" })}>
              <i className={"fa fa-file"} ></i> <br /> Content
            </button>
          </li>
          <li className="nav-item mx-1" role="presentation">
            <button className={"nav-link " + styles.navlink + " " + (this.state.activeTab == "1" ? "active" : "")} style={{ backgroundColor: this.props.sideBarColor + '99', color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "1" })}>
              <i className={"fas fa-palette"} ></i> <br /> Colors
            </button>
          </li>
          <li className="nav-item mx-1" role="presentation">
            <button className={"nav-link " + styles.navlink + " " + (this.state.activeTab == "2" ? "active" : "")} style={{ backgroundColor: this.props.sideBarColor + '99', color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "2" })}>
              <i className={"fas fa-image"} ></i> <br /> Backgrounds
            </button>
          </li>
          <li className="nav-item mx-1" role="presentation">
            <button className={"nav-link " + styles.navlink + " " + (this.state.activeTab == "3" ? "active" : "")} style={{ backgroundColor: this.props.sideBarColor + '99', color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "3" })}>
              <i className={"fas fa-palette"} ></i> <i className={"fas fa-image"} ></i> <br /> Themes
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent" style={{ padding: `10%`, flex: `auto`, display: `flex`, flexDirection: `column`, justifyContent: `center` }}>
          {this.state.activeTab == "-1" ? <HomePage width={this.props.width} sideBarColor={this.state.sideBarColor}
            sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} /> : <></>}
          {this.state.activeTab == "0" ? <ContentTab sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2}
            shownPages={this.state.shownPages} setWebsiteView={this.setWebsiteView} /> : <></>}
          {this.state.activeTab == "1" ? <ColorPreferencesTab sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2}
            setSideBarColor={this.setSideBarColor} setSideBarColor2={this.setSideBarColor2} /> : <></>}
          {this.state.activeTab == "2" ? <BackgroundPreferencesTab sideBarColor={this.state.sideBarColor} selectedImage={this.state.selectedImage} selectImage={this.selectImage} /> : <></>}
          {this.state.activeTab == "3" ? <ThemesTab sideBarColor={this.state.sideBarColor} setTheme={this.setTheme} width={this.props.width} /> : <></>}
        </div>
        {this.state.activeTab != "-1" ?
          <button type="button" disabled={this.state.saveButton} className="btn btn-primary" style={{ backgroundColor: this.props.sideBarColor, border: `1.5px solid`, borderColor: this.props.sideBarColor2 }} onClick={() => this.props.SavePrefs(this.state.sideBarColor, this.state.sideBarColor2, this.state.selectedImage, this.state.shownPages)}>Choose</button> :
          <button type="button" className="btn btn-secondary" onClick={() => this.props.setPreferencesTabOpen(false)} style={{ backgroundColor: this.props.sideBarColor, border: `1.5px solid`, borderColor: this.props.sideBarColor2 }}>Go to website</button>
        }
      </div>
    );
  }
}
export default PreferencesTab;