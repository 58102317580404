import React from 'react';
import styles from './Album.module.scss';
class Album extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        
      };
    }

    render() {
      return (
        <div className={styles.block} style={{textAlign: `center`}}>
        <div class={styles.overlay} onClick={() => this.props.onAlbumSelect(this.props.id)}>
          <img src="http://drh.img.digitalriver.com/DRHM/Storefront/Site/atvi/cm/images/site/play-button-overlay.png" alt="Play" />
        </div>
        <img src={this.props.coverImage? this.props.coverImage:"https://upload.wikimedia.org/wikipedia/en/7/78/Octoberrust.jpg"} alt="Image" />
        {this.props.name}
      </div>
      );
    }
  }
  export default Album;