import React, { Children } from 'react';
import styles from './WebDevOverview.module.scss'
import CSharp from '../../../shared/logos/CSharp.png'
import Java from '../../../shared/logos/Java.png'
import Php from '../../../shared/logos/Php.png'
import TS from '../../../shared/logos/Typescript.png'
import JS from '../../../shared/logos/Javascript.png'
import Sql from '../../../shared/logos/Sql.png'
import ReactP from '../../../shared/logos/React.png'
import Docker from '../../../shared/logos/Docker.png'
import Aurelia from '../../../shared/logos/Aurelia.png'
import Angular from '../../../shared/logos/Angular.png'
import Gitlab from '../../../shared/logos/Gitlab.png'
import RabbitMQ from '../../../shared/logos/RabbitMQ.png'
import Sonar from '../../../shared/logos/Sonarqube.png'
import Kibana from '../../../shared/logos/Kibana.png'
import Hubble from '../../../shared/logos/Hubble.png'
import Jira from '../../../shared/logos/Jira.png'
import Azure from '../../../shared/logos/Azure.png'
import AzureAd from '../../../shared/logos/AzureAd.png'
import AzureAppS from '../../../shared/logos/AzureAppS.png'
import AzureRedis from '../../../shared/logos/AzureRedis.png'
import AzureSql from '../../../shared/logos/AzureSql.png'
import Aws from '../../../shared/logos/Aws.png'
import AwsIoT from '../../../shared/logos/AwsIoT.png'
import AwsRds from '../../../shared/logos/AwsRds.png'
import Laravel from '../../../shared/logos/Laravel.png'
import Asp from '../../../shared/logos/Asp.png'
import Datadog from '../../../shared/logos/Datadog.png'
import Github from '../../../shared/logos/Github.png'
import cloudCover from '../../../shared/cloud.jpg'
import webdevcover from '../../../shared/webdevcover.jpg'
import toolcover from '../../../shared/toolcover.jpg'
import frameworkcover from '../../../shared/frameworkcover.jpg'
import Slider from "react-slick";

const logo = require('../../../shared/grain.png');

class WebDevDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const settings = {
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            centerMode: true,
            initialSlide: 1,
            dots: true
        };

        return (
            <Slider {...settings}>
                <FlipCard source={webdevcover}>
                    <p>Web Development</p>
                    <ul className={styles.list + " mb-0"} style={{ padding: 0, maxHeight: `100%`, display: `grid`, gridTemplateColumns: `1fr 1.5fr 1fr`, gap: `10px` }}>
                        <li><img src={CSharp} className={styles.grow} /></li>
                        <li><img src={TS} className={styles.grow} /></li>
                        <li><img src={Sql} className={styles.grow} /></li>
                        <li><img src={Java} className={styles.grow} /></li>
                        <li><img src={JS} className={styles.grow} /></li>
                        <li><img src={Php} className={styles.grow} /></li>
                    </ul>
                </FlipCard>

                <FlipCard source={toolcover}>
                    <p>Tools</p>
                    <ul className={styles.list + " mb-0"} style={{ padding: 0, maxHeight: `100%`, display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr` }}>
                        <li /><li /><li><img src={Github} className={styles.grow} /></li><li /><li />
                        <li /><li><img src={Gitlab} className={styles.grow} /></li>
                        <li /><li><img src={RabbitMQ} className={styles.grow} /></li>
                        <li /><li><img src={Jira} className={styles.grow} /></li>
                        <li /><li><img src={Docker} className={styles.grow} /></li>
                        <li /><li><img src={Sonar} className={styles.grow} /></li>
                        <li /><li><img src={Hubble} className={styles.grow} /></li>
                        <li /><li><img src={Kibana} className={styles.grow} /></li>
                        <li /><li /><li /><li><img src={Datadog} className={styles.grow} /></li>
                    </ul>
                </FlipCard>
                <FlipCard source={frameworkcover}>
                    <p>Frameworks</p>
                    <ul className={styles.list + " mb-0"} style={{ padding: 0, maxHeight: `100%`, display: `grid`, gridTemplateColumns: `1fr 1fr 1fr` }}>
                        <li><img src={Aurelia} className={styles.grow} /></li><li />
                        <li><img src={ReactP} className={styles.grow} /></li>
                        <li></li>
                        <li><img src={Asp} className={styles.grow} /></li>
                        <li />
                        <li><img src={Laravel} className={styles.grow} /></li><li></li>
                        <li><img src={Angular} className={styles.grow} /></li>
                    </ul>
                </FlipCard>
                <FlipCard source={cloudCover}>
                    <p>Cloud services</p>
                    <div>
                        <ul className={styles.list + " mb-0"} style={{ padding: 0, maxHeight: `100%`, display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 3fr 1fr 1fr 1fr` }}>
                            <li /><li /><li><img src={AzureAd} className={styles.grow} /></li><li /><li><img src={AzureAppS} className={styles.grow} /></li><li /><li />
                            <li /><li><img src={AzureRedis} className={styles.grow} /></li><li /><li><img src={Azure} className={styles.grow} /></li><li /><li><img src={AzureSql} className={styles.grow} /></li><li />
                            <li /><li /><li>*</li><li></li><li>*</li><li /><li />
                        </ul>

                        <ul className={styles.list + " mb-0"} style={{ padding: 0, maxHeight: `100%`, display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 3fr 1fr 1fr 1fr` }}>
                            <li /><li /><li>*</li><li /><li>*</li><li /><li />
                            <li /><li>*</li><li /><li><img src={Aws} className={styles.grow} /></li><li /><li>*</li><li />
                            <li /><li /><li><img src={AwsIoT} className={styles.grow} /></li><li></li><li><img src={AwsRds} className={styles.grow} /></li><li /><li />
                        </ul>
                    </div>
                </FlipCard>
            </Slider>
        );
    }

};

class FlipCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (<div className={styles.cell} style={{
            borderStyle: `solid`, borderRadius: `1%`, textAlign: `-webkit-center`,
            backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 100%)'
        }}>
            <div className={styles.flipCard} style={{
                background: `url(${this.props.source})`, backdropFilter: `blur(30px)`,
                backgroundPosition: `bottom`
            }}>
                <div className={styles.flipCardInner}>
                    <div className={styles.flipCardFront}>
                        <img src={this.props.source} style={{ maxHeight: `none`, objectFit: `scale-down` }} />
                    </div>
                    <div className={styles.flipCardBack}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default WebDevDashboard;
