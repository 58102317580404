import React from 'react';

class ArtHeader extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        
      };
    }
    exampleFunction() {
      
    }
    
    render() {
      return (
        <section class="jumbotron text-center" >
        <div class="container" style={{
          padding:0,
          paddingTop:`2%`}}>
          <h1 class="jumbotron-heading trapezoid" style={{padding: 0, mixBlendMode: `unset`, borderBottom: `50px solid ` + this.props.color, margin:0, borderRadius: `1rem 1rem 0rem 0rem`}}>{this.props.title}</h1>
        </div>
      </section>
      );
    }
  }
  export default ArtHeader;