import React from 'react';
import styles from './BlogPage.module.css';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'
const logo = require('../../../shared/grain.png');

class FNSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {}

  componentDidUpdate() { }

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props.width < 768 ? 1 : 1,
      slidesToScroll: 1,
      draggable: false,
      swipe: true
    };

    return (
      <>
      <div>
        <h4>Personas</h4>
      </div>
      <div>
        <h4>Combat things</h4>
      </div>
      </>
    );
  }
}
export default FNSlide;