import React from 'react';
import PresentCard from '../../../shared/components/PresentCard/PresentCard';
import SoccerTrick from '../../../shared/SoccerTrickHome.jpg';
import WorkPose from '../../../shared/WorkPose.jpg';
import WalkToSun from '../../../shared/WalkToSun.jpg';
import styles from './HomePage.module.scss';
import WebsiteIntroduction from '../WebsiteIntroduction/WebsiteIntroduction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'
const logo = require('../../../shared/grain.png');

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHeight: `calc(var(--doc-height)*.5)`,
      currentDegrees: 0,
      currentIndex: 1,
      indexes: ["1", "2", "3", "4"],
      ThreeDeeMode: false,
      WelcomeTexts: ["Welcome to the dark side, suprised we lied about the cookies?", "A new member you have become, a warm welcome you shall have",
       "You have been chosen, welcome", "Here's to your presence", "All visitors are to be approved by the dog", "Enter at own risk", "Before you enter, check your energy"],
      WelcomeTextIndex: 0
    };

    this.myRef = React.createRef();
    this.carousel = this.carousel.bind(this)
  }
  componentDidMount() {
    this.carousel()
  }

  componentDidUpdate() {
    // console.log("Home page rerendered")
  }

  rotate(degrees) {
    var index = this.state.currentIndex;
    if (degrees == 90) index--
    else index++
    this.setState({ ...this.state, currentDegrees: this.state.currentDegrees + degrees, currentIndex: index })
  }

  getCircularIndex(currentPos, chooseFromArr) {
    const i = currentPos + 1
    const n = chooseFromArr.length
    return chooseFromArr[(i % n + n) % n]
  }

  determineTransform(width, index) {
    var currentIndex1 = this.getCircularIndex(this.state.currentIndex, this.state.indexes)
    var currentIndex2 = this.getCircularIndex(this.state.currentIndex + 1, this.state.indexes)
    var currentIndex3 = this.getCircularIndex(this.state.currentIndex + 2, this.state.indexes)
    var currentIndex4 = this.getCircularIndex(this.state.currentIndex + 3, this.state.indexes)
    if (currentIndex3 == index) return 0
    if (currentIndex1 == index || currentIndex3 == index) {
      return 300
    }

    if (currentIndex2 == index || currentIndex4 == index)
      return parseInt(width * 0.7) / 2
  }

  determineIsBehind(index) {
    var currentIndex1 = this.getCircularIndex(this.state.currentIndex, this.state.indexes)
    if(currentIndex1 == index){
      return styles.hide
    }
  }

  carousel() {
    var index = Math.floor(Math.random() * this.state.WelcomeTexts.length)
    this.setState({...this.state, WelcomeTextIndex: index})
    setTimeout(this.carousel, 10000);
  }

  render() {
    return (
      <>
        <div className={styles.carouselContainer + " " + styles.mobilePaddingTop} style={{backgroundColor: this.props.sideBarColor +'99', "perspective": this.state.ThreeDeeMode ? "1000px" : "none" }}>
          <div className={styles.buttonBar + " " + ( styles.hide)}>
            <div className={styles.next} style={{backgroundColor: this.props.sideBarColor, borderRadius: `10px`, borderBottomLeftRadius: 0, borderTopLeftRadius: 0}} onClick={() => this.rotate(-90)}>
              <FontAwesomeIcon icon={faCaretSquareRight} size='lg' color={this.props.sideBarColor}/>
            </div>
            <div className={styles.middle} onClick={() => this.setState({ ...this.state, ThreeDeeMode: !this.state.ThreeDeeMode })} data-title="3D Toggle">
              <i className={"fas fa-cube"} ></i>
            </div>
            <div className={styles.prev} style={{backgroundColor: this.props.sideBarColor, borderRadius: `10px`, borderBottomRightRadius: 0, borderTopRightRadius: 0}} onClick={() => this.rotate(90)}>
              <FontAwesomeIcon icon={faCaretSquareLeft} size='lg' color={this.props.sideBarColor}/>
            </div>
          </div>
          <div className={styles.carousel} style={{
            "-webkit-transform": "rotateY(" + this.state.currentDegrees + "deg)",
            "-moz-transform": "rotateY(" + this.state.currentDegrees + "deg)",
            "-o-transform": "rotateY(" + this.state.currentDegrees + "deg)",
            "transform": "rotateY(" + this.state.currentDegrees + "deg)"
          }}>
            <div className={this.determineIsBehind(1) + " " + styles.item + " " + styles.a + " "} id="style-15" style={{
              transform: `rotateY(0deg) translateZ(${this.determineTransform(this.props.width, 1)}px)`,
              placeContent: `center`, margin: '0px'
            }}>
              <PresentCard title={this.state.WelcomeTexts[this.state.WelcomeTextIndex]} link='' showLink={false} sideBarColor={this.props.sideBarColor} sideBarColor2={this.props.sideBarColor2}
                image="" style={'shape'} showText={true}
                text="" />
            </div>
            <div id="style-15" className={this.determineIsBehind(2) + " " + styles.item + " " + styles.b} style={{
              transform: `rotateY(90deg) translateZ(${this.determineTransform(this.props.width, 2)}px)`,justifyContent: `center`
              , backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', margin: '0px'
            }}>
              <PresentCard title='My skills' link='/skills' sideBarColor={this.props.sideBarColor} sideBarColor2={this.props.sideBarColor2}
                image={WorkPose} style={'shape'}
                text='I specialize as a fullstack web developer.
               Part of my skills have enabled me to develop this website (ReactJS, Express, HTML5 + CSS)' showText={this.props.width > 769}/>
            </div>
            <div id="style-15" className={this.determineIsBehind(3) + " " + styles.item + " " + styles.c} style={{
              transform: `rotateY(180deg) translateZ(${this.determineTransform(this.props.width, 3)}px)`, justifyContent: `center`
              , backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', margin: '0px'
            }}>
              <PresentCard title='Hobbies' link='/hobbies' sideBarColor={this.props.sideBarColor} sideBarColor2={this.props.sideBarColor2}
                image={SoccerTrick} style={'shape2'}
                text='I have a few hobbies which I like to do. I am a big fan of music, drawing, skating, and overall playing sports. 
               Maybe you do the same!' showText={this.props.width > 769}/>
            </div>
            <div id="style-15" className={this.determineIsBehind(4) + " " + styles.item + " " + styles.d} style={{
              transform: `rotateY(270deg) translateZ(${this.determineTransform(this.props.width, 4)}px)`,justifyContent: `center`
              , backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)', margin: '0px'
            }}>
              <PresentCard title='Contact' link='/about' sideBarColor={this.props.sideBarColor} sideBarColor2={this.props.sideBarColor2}
                image={WalkToSun} style={'shape3'}
                text='So you’ve read this far, did you find something interesting? 
               We might share a similar interest! Feel free to contact me.' showText={this.props.width > 769}/></div>
          </div>
        </div >
      </>
    );
  }
}
export default HomePage;