import React from 'react';
import styles from './AboutPage.module.scss';
import HorizontalTimeLine from "../../../shared/components/HorizontalTimeLine/HorizontalTimeLine";
import DigitalMe from '../../../shared/DigitalMe.png';
import { Link } from 'react-scroll';
import Roy from '../../../shared/RoyC.svg';
import { fab, faFacebook, faTwitter, faInstagram, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {faChevronCircleDown, faChevronCircleUp, faEnvelope, faMobile} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightOrDark } from '../../../shared/Utils';

const logo = require('../../../shared/grain.png');

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timelineOpen: false,
      isActive: false,
      flippableCardOpen: false,
      divHeight: 0,
      startHeight: 0
    };
    this.article = React.createRef();
    this.setActive = this.setActive.bind(this)
    this.setFlippableCardOpen = this.setFlippableCardOpen.bind(this)
    this.targetDivRef = React.createRef();
    this.targetDivRef2 = React.createRef();
    this.startPos = 0;
  }
  componentDidMount() {
    var mut = new MutationObserver(this.setActive);
    mut.observe(document.querySelector("#about"), {
      'attributes': true
    });

    this.props.changeBackgroundImage();
    this.setState({...this.state, startHeight: this.article.current?.offsetHeight})
  }

  setFlippableCardOpen() {
    this.setState((prevState) => ({ ...this.state, flippableCardOpen: !prevState.flippableCardOpen }), () => this.updateArticleHeight())
  }

  setActive(mutations, mut) {
    this.setState({ ...this.state, isActive: !this.isActive })
  }

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  updateArticleHeight() {
    if (this.article.current) {
      const divHeight = this.state.flippableCardOpen ? this.article.current?.offsetHeight: this.state.startHeight;
      this.setState({ ...this.state, divHeight: divHeight });
    }
  }

  render() {
    return (
      <div className={this.state.isActive ? "ElementInactive" : "ElementActive"}>
        <div className={styles.AboutPage + " d-flex"} style={{ borderRadius: `10px`, flexDirection: `row` }}>

          <article className="container" style={{ alignSelf: "center", paddingLeft: 0, paddingRight: 0 }}>
            <section className='d-flex' style={{ borderRadius: `10px`, backgroundColor: this.props.sideBarColor + 'E6'}}>
              <img id="me" src={Roy} style={{ maxHeight: this.state.divHeight == 0 ? this.article.current?.offsetHeight : this.state.divHeight, position: `absolute`, opacity: `.4`, right: 0, marginRight: `15vw` }} className={"center-image " + styles.meImage} alt="Image" loading='lazy'></img>

              {/* <aside className={styles.aside + " text-center"} id="style-15" style={{
                position: `sticky`, top: 0, flexDirection: `row`, borderRadius: `10px 10px`, width: `80%`,
                maxHeight: this.state.divHeight == 0 ? this.article.current?.offsetHeight : this.state.divHeight, overflowY: `scroll`
              }}>
                <Link to={"/skills"} smooth={false} duration={500} isDynamic={true} ignoreCancelEvents={true}>
                  <div className={styles.box + " Blur"}>
                    <img src={this.props.width < 756 ? "" : process.env.PUBLIC_URL + `/WorkPose${this.state.isActive ? "" : "S"}.jpg`} loading='lazy' />
                    <h3 className={styles.text} >
                      Skills
                    </h3>
                  </div>
                </Link>

                <Link to={"/hobbies"} smooth={false} duration={500} isDynamic={true} ignoreCancelEvents={true}>
                  <div className={styles.box + " Blur"}>
                    <img src={this.props.width < 756 ? "" : process.env.PUBLIC_URL + `/SoccerTrick${this.state.isActive ? "" : "S"}.jpg`} loading='lazy' />
                    <h3 className={styles.text} >
                      <div>
                        <span className={styles.innertext}>Hobbies</span>
                      </div>
                    </h3>
                  </div>
                </Link>

                <Link to={"/music"} smooth={false} duration={500} isDynamic={true} ignoreCancelEvents={true}>
                  <div className={styles.box + " Blur"}>
                    <img src={this.props.width < 756 ? "" : process.env.PUBLIC_URL + `/dance${this.state.isActive ? "" : "S"}.jpg`} />
                    <h3 className={styles.text} >
                      <div>
                        <span className={styles.innertext}>Music</span>
                      </div>
                    </h3>
                  </div>
                </Link>

                <Link to={"/art"} smooth={false} duration={500} isDynamic={true} ignoreCancelEvents={true}>
                  <div className={styles.box + " Blur"}>
                    <img src={this.props.width < 756 ? "" : process.env.PUBLIC_URL + `/FirefliesArt${this.state.isActive ? "" : "S"}.jpg`} />
                    <h3 className={styles.text} >
                      <div>
                        <span className={styles.innertext}>Art</span>
                      </div>
                    </h3>
                  </div>
                </Link>

                <Link to={"/statistics"} smooth={false} duration={500} isDynamic={true} ignoreCancelEvents={true}>
                  <div className={styles.box + " Blur"}>
                    <img src={this.props.width < 756 ? "" : process.env.PUBLIC_URL + `/statistics${this.state.isActive ? "" : "S"}.jpg`} />
                    <h3 className={styles.text} >
                      <div>
                        <span className={styles.innertext}>Statistics</span>
                      </div>
                    </h3>
                  </div>
                </Link>
              </aside> */}

              <section className={styles.IntroAbout + " d-flex"} ref={this.article} style={{
                borderStyle: `solid`, borderRadius: `10px `, justifyContent: `space-evenly`, flexDirection: `column`, padding: '3rem 3rem .5rem 3rem'
              }}>
                <h1 style={{ textAlign: 'start' }}>About myself</h1>
                <p style={{ zIndex: `10` }}>
                  Hello there, it’s me, Roy. I am currently {this.getAge("2000/04/18")} years old.<br />
                  I come from Arnhem, The Netherlands. <br />
                  I like to dance, do a bit of boxing, fitness, skating, soccer and swimming.<br />
                  Outside these physical activities I like to go drawing, programming and play games.<br />
                  I'm also pretty well into music, as my mind repeats songs.
                  <br /><br />
                  On this site which “represents” me I would love to share with you some of my experiences, skills and hobbies so that we can enjoy them together.<br />
                  Maybe you find a new awesome song or are interested in what my skills are.<br />
                  This site is here for you to explore me, and if you want, to get in touch!
                </p>
                {/* {this.props.width < 756 ? <></> : <HorizontalTimeLine sideBarColor={this.props.sideBarColor} />} */}

                <section className="my-2" style={{
                  borderStyle: `solid`, borderRadius: `1%`, padding: `1rem`, zIndex: 1, opacity: `.8`, paddingLeft: 0
                }}>
                  <div>
                    <div style={{ display: `flex`, alignItems: `center`, paddingTop: `.5rem` }}>
                      {/* <img src={DigitalMe} className="rounded-circle" width="140" alt="Image"></img> */}
                      <div>
                        <p>Fullstack Web Developer</p>
                        <a className={styles.iconBox} style={{ padding: `0 0.5rem 0 0` }} href="https://www.linkedin.com/in/roy-g-693a4017b" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                        <a className={styles.iconBox} style={{ padding: `0 0.5rem 0 0` }} href="mailto:rlaginsel@gmail.com" target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
                        <a className={styles.iconBox} style={{ padding: `0 0.5rem 0 0` }} href="https://github.com/RoyGinsel" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
                      </div></div>
                  </div>
                </section>
              </section>
            </section>
          </article>
        </div>
      </div>
    );
  }
}
export default AboutPage;