import React from 'react';
import styles from './HorizontalTimeLine.module.scss'
import { lightOrDark } from '../../../shared/Utils';

class HorizontalTimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return(<>
      <div className={styles.flexParent}>
        <div className={styles.inputFlexContainer}>
          <div className={styles.input + " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2000" data-info="Birth Year"></span>
          </div>
          <div className={styles.input+ " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2012" data-info="Primary School"></span>
          </div>
          <div className={styles.input+ " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2017" data-info="High School HAVO NT"></span>
          </div>
          <div className={styles.input+ " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2019" data-info="Internship Software Engineer"></span>
          </div>
          <div className={styles.input+ " time"}style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2021" data-info="Bachelor Web Development"></span>
          </div>
          <div className={styles.input+ " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2021" data-info="Software Engineer CCV Group"></span>
          </div>
          <div className={styles.input+ " time"} style={{backgroundColor: this.props.sideBarColor, border: `1px solid`, borderColor: lightOrDark(this.props.sideBarColor)}}>
            <span className={styles.span} data-year="2022" data-info="Drivers License"></span>
          </div>
        </div>
      </div>
      <style>
        {
          `.time:before {
            background-color: ${this.props.sideBarColor};
          }
          .time:after {
            background-color: ${this.props.sideBarColor};
          }`
        }
      </style>
    </>)
  }
}

export default HorizontalTimeLine;
