import './App.css';
import React from 'react';
import { ShowBooleanSwitch } from './shared/Utils';
import background from './shared/bckgrnd2.png'
import ArtPage from './pages/components/ArtPage/ArtPage';
import BlogPage from './pages/components/BlogPage/BlogPage';
import PreferencesTab from './pages/components/PreferencesTab/PreferencesTab';
import StatisticsPage from './pages/components/StatisticsPage/StatisticsPage';
import SkillsPage from './pages/components/SkillsPage/SkillsPage';
import AboutPage from './pages/components/AboutPage/AboutPage';
import MusicPage from './pages/components/MusicPage/MusicPage';
import NavMenuTimeLine from './shared/classComponents/NavMenuTimeLine/NavMenuTimeLine';
import { lightOrDark } from './shared/Utils';
import { Element } from 'react-scroll'
import StatisticsPageDataService from './services/StatisticsPage.service';
import HobbyPage from './pages/components/HobbyPage/HobbyPage';
import { ntc } from './ntc';
import WebDevOverview from './pages/components/WebDevOverview/WebDevOverview';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.ShowBooleanSwitch = ShowBooleanSwitch.bind(this);
    this.state = {
      width: 0,
      height: 0,
      backgroundImage: background,
      ShrinkSidebar: false,
      SidebarClass: "active",
      SidebarIconClass: "fa-caret-left",
      sideBarColor: localStorage.getItem('sideBarColor') != null ? localStorage.getItem('sideBarColor') : '#2c3e50',
      sideBarColor2: localStorage.getItem('sideBarColor2') != null ? localStorage.getItem('sideBarColor2') : '#bdc3c7',
      textColor: lightOrDark(localStorage.getItem('sideBarColor') != null ? localStorage.getItem('sideBarColor') : '#2c3e50'),
      selectedImage: localStorage.getItem('backgroundImage') != null ? localStorage.getItem('backgroundImage') : "backgroundcityblue.gif",
      lastUpdated: "",
      shownPages: localStorage.getItem('pages') != null ? localStorage.getItem('pages') : "/,/about, /skills, /statistics",
      preferencesTabOpen: true
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleScrollLock = this.toggleScrollLock.bind(this);
    this.setPreferencesTabOpen = this.setPreferencesTabOpen.bind(this)
    this.activeRouteWithoutState = "";

    this.mapping = {
      "/": "Home",
      "/about": "About",
      "/skills": "Skills",
      "/hobbies": "Hobby",
      "/music": "Music",
      "/art": "Art",
      "/games": "Games",
      "/statistics": "Statistics"
    }
  }

  getLastUpdated() {
    StatisticsPageDataService.getBuildInfo().then((buildInfo) => {
      this.setState({ ...this.state, lastUpdated: buildInfo.data[0].dateBuild })
    })
  }

  toggleScrollLock(toggle) {
    if (toggle) {
      var top = window.pageYOffset || document.documentElement.scrollTop;
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function (ev) {
        window.onscroll = null;
        window.scrollTo(scrollLeft, top);
      };
    } else {
      window.onscroll = null;
    }

    document.getElementsByTagName('body')[0].style.setProperty('overflow', toggle ? "hidden" : "auto")
  };

  updateSidebarProps = () => {
    this.ShowBooleanSwitch("ShrinkSidebar", this.state.ShrinkSidebar);
    let cssClass = !this.state.ShrinkSidebar ? "inActive" : "active";
    let cssIconClass = !this.state.ShrinkSidebar ? "fa-caret-right" : "fa-caret-left";
    this.setState({
      SidebarClass: cssClass,
      SidebarIconClass: cssIconClass
    })
  }
  updateColor = () => {
    this.setState({
      ...this.state,
      sideBarColor: localStorage.getItem('sideBarColor') != null ? localStorage.getItem('sideBarColor') : '#2c3e50',
      textColor: lightOrDark(localStorage.getItem('sideBarColor') != null ? localStorage.getItem('sideBarColor') : '#2c3e50')
    })
  }

  SavePrefs = (color, color2, img, shownPages) => {
    var nameColor = ntc.name(color)
    this.setState({
      ...this.state,
      sideBarColor: color,
      sideBarColor2: color2,
      selectedImage: img,
      shownPages: shownPages
    }, () => {
      localStorage.setItem('sideBarColor', this.state.sideBarColor);
      localStorage.setItem('sideBarColor2', this.state.sideBarColor2);
      localStorage.setItem('backgroundImage', this.state.selectedImage);
      localStorage.setItem('pages', this.state.shownPages)
      this.updateColor();
      StatisticsPageDataService.incrementColor({ color: nameColor[3] });
    }
    )
  }

  changeBackgroundImage = (image) => {
    this.setState({
      ...this.state,
      backgroundImage: image
    })
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // window.addEventListener('keypress', () => this.toggleScrollLock(true));
    var x = document.getElementsByTagName("input");
    for (var i = 0; i < x.length; i++) {
      var htmlElement = x[i]
      htmlElement.addEventListener('keypress', () => this.toggleScrollLock(true));
      htmlElement.addEventListener('focusout', () => this.toggleScrollLock(false));
    }

    document.documentElement.style.setProperty('--background-color', this.state.sideBarColor)
    this.getLastUpdated()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  }

  focusOut() {
    console.log("out")
  }

  onSetActive = async (to, element) => {
    element.classList.remove("preview-block")
    element.classList.add("transitionlol")
    this.activeRouteWithoutState = to

    this.delay(1000).then(() => {
      if (this.activeRouteWithoutState == to)
        this.UpdatePageVist(this.activeRouteWithoutState)
    })
  }

  onSetInactive = (to, element) => {
    element.classList.add("preview-block")
  }

  UpdatePageVist(url) {
    var query = { name: this.mapping[url] };
    StatisticsPageDataService.increment(query);
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  show(name) {
    if (this.state.shownPages.includes(name))
      return ""
    else
      return " d-none"
  }

  setPreferencesTabOpen(bool) {
    this.setState({ ...this.state, preferencesTabOpen: bool })
  }

  render() {
    return (
      <div className="app pageBack mobileContainer" id="style-15"
        style={{
          backgroundImage: `url(${this.state.selectedImage}),radial-gradient(ellipse at bottom, ${this.state.sideBarColor2} 15%, ${this.state.sideBarColor} 120%)  `,
          mixBlendMode: `multiply`,
          backgroundSize: `cover`,
          maxWidth: `100vw`,
          overflowX: `hidden`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
          minHeight: `100vh`
        }}>
        {this.state.preferencesTabOpen ? <PreferencesTab setPreferencesTabOpen={this.setPreferencesTabOpen} width={this.state.width} shownPages={this.state.shownPages} SavePrefs={this.SavePrefs} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} selectedImage={this.state.selectedImage} />
          : <>
            <NavMenuTimeLine setPreferencesTabOpen={this.setPreferencesTabOpen} shownPages={this.state.shownPages} onSetActive={this.onSetActive} onSetInactive={this.onSetInactive} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} textColor={this.state.textColor} />
            <WebDevOverview sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} />
            <div>
              <Element id="about" name="/about" className={"element preview-block" + this.show("/about")}>
                <AboutPage width={this.state.width} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element>
              <Element name="/skills" className={"element preview-block" + this.show("/skills")} style={{ backgroundSize: `cover`, border: `10px solid` + this.props.sideBarColor, borderTop: 0 }}>
                <SkillsPage width={this.state.width} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element>
              {/* <Element id="hobbies" name="/hobbies" className={"element preview-block" + this.show("/hobbies")} style={{ backgroundSize: `cover`, border: `10px solid` + this.props.sideBarColor, borderTop: 0 }}>
                <HobbyPage sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element> */}
              <Element name="/music" className={"element preview-block" + this.show("/music")} style={{ backgroundSize: `cover`, border: `10px solid` + this.props.sideBarColor, borderTop: 0 }}>
                <MusicPage sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element>
              <Element id="art" name="/art" className={"element preview-block" + this.show("/art")}>
                <ArtPage sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} width={this.state.width} />
              </Element>
              <Element id="blog" name="/games" className={"element preview-block mobilePaddingg" + this.show("/games")}>
                <BlogPage width={this.state.width} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element>
              <Element name="/statistics" className={"element pb-5 mobilePaddingg" + this.show("/statistics")}>
                <StatisticsPage width={this.state.width} sideBarColor={this.state.sideBarColor} sideBarColor2={this.state.sideBarColor2} changeBackgroundImage={this.changeBackgroundImage} />
              </Element>
              <div style={{ textAlign: `center` }}>©{new Date().getFullYear()} RoyGinsel.nl</div>
              <div style={{ textAlign: `center` }}>Last updated: {this.state.lastUpdated}</div>
            </div >
          </>}


        <style>
          {`* { color: ${this.state.textColor};}
            [data-title]:after {
              content: attr(data-title);
              background-color: ${this.state.sideBarColor};
              color: ${this.state.textColor};
              font-size: 50%;
              position: absolute;
              padding: 1px 5px 2px 5px;
              white-space: nowrap;
              border: 1px solid #111111;
              z-index: 99999;
              visibility: hidden;
              border-radius: 10px;
            }`}
        </style>
      </div>
    );
  }
}

export default App;
