import React from 'react';
import WebDevDashboard from './WebDevDashboard';

class WebDevOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeTab: "1"
    };
  }

  render() {
    return (
      <div className="modal fade" id="WebDevModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
        style={{ backgroundImage: 'radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + '19 0%, ' + this.props.sideBarColor + '80 70%)' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ backgroundImage: 'radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)' }}>
            <div className="modal-body" style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
              <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ justifyContent: `center`, border: 0 }}>
                <li className="nav-item mx-1" role="presentation">
                  <button className={"nav-link " + (this.state.activeTab == "1" ? "active" : "")} style={{ background: `none`, color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "1" })}>
                    <i className={"fas fa-table"} ></i> <br/> Overview
                  </button>
                </li>
                <li className="nav-item mx-1" role="presentation">
                  <button className={"nav-link " + (this.state.activeTab == "2" ? "active" : "")} style={{ background: `none`, color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "2" })}>
                  <i className={"fas fa-globe"} ></i> <br/> Web
                    </button>
                </li>
                <li className="nav-item mx-1" role="presentation">
                  <button className={"nav-link " + (this.state.activeTab == "3" ? "active" : "")} style={{ background: `none`, color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "3" })}>
                  <i className={"fas fa-cloud"} ></i> <br/> Cloud
                    </button>
                </li>
                <li className="nav-item mx-1" role="presentation">
                  <button className={"nav-link " + (this.state.activeTab == "4" ? "active" : "")} style={{ background: `none`, color: `inherit` }} onClick={() => this.setState({ ...this.state, activeTab: "4" })}>
                  <i className={"fas fa-toolbox"} ></i> <br/> Tools
                    </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent" style={{ padding: `1rem 1rem 2rem 1rem` }}>
                {this.state.activeTab == "1" ? <WebDevDashboard sideBarColor={this.props.sideBarColor} sideBarColor2={this.props.sideBarColor2}/> : <></>}
                {this.state.activeTab == "2" ? <h1>Topic</h1> : <></>}
                {/* //Slider format topics one at a time */}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default WebDevOverview;