import http from "../http-common";

class ArtPageDataService {
  getAll() {
    return http.get("/artpage");
  }

  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  create(data) {
    return http.post("/artpage", data);
  }

  increment(data){
    return http.put("/artpage/increment", data);
  }

  decrease(data){
    return http.put("/artpage/decrease", data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
}
export default new ArtPageDataService();
