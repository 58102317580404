import React from 'react';
import StatisticsPageDataService from '../../../services/StatisticsPage.service';

class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          inputs: {
            time: "",
            kms: "",
            date: ""
          }
        }
      }

      handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var local = this.state.inputs;
        local[name] = value;
        this.setState(({...this.state, inputs: local}))
      }

      handleSubmit = (event) => {
        event.preventDefault();
        StatisticsPageDataService.createFitnessDocument(this.state.inputs).then((res) => console.log(res));
      }

      render(){
        return(
        <>
        <h1>Hello this is the admin page</h1>
        <form onSubmit={this.handleSubmit}>
            <label>Time:<input type="text" name="time" value={this.state.inputs.time || ""} onChange={this.handleChange}/></label>
            <label>Kilometers:<input type="text" name="kms" value={this.state.inputs.kms || ""} onChange={this.handleChange}/></label>
            <label>Date:<input type="text" name="date" value={this.state.inputs.date || ""} onChange={this.handleChange}/></label>

            <input type="submit" />
        </form>
        </>)
      }
}

export default AdminPage;