import React from 'react';
import styles from './CardContainer.module.scss';

class CardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className={!this.props.notflexablecontent ? 'd-flex' : ''} style={{ borderRadius: `15px`, padding: `5%`, backgroundColor: this.props.sideBarColor + 'E6', flexDirection: `column`, alignItems: `center` }}>
                <div className='d-flex' style={{ width: `100%`, justifyContent: `space-between`, marginBottom: `1rem` }}>
                    <span style={{ alignSelf: `center`, position: `relative`, top: `-15px` }}>
                        <i className={this.props.icon}></i>
                    </span>
                    <h4 style={{ display: `inline`, fontWeight: `600` }}> {this.props.title}</h4>
                    {this.props.instructions ?
                        <span style={{ alignSelf: `center`, position: `relative`, top: `-15px` }} data-title={this.props.instructions}>
                            <i className='fa fa-info-circle'></i>
                        </span> : <></>}
                </div>

                {this.props.children}
            </div>
        );
    }
}
export default CardContainer;