import React from 'react';
import { Impress, Step } from 'react-impressjs';
// style of react-impressjs
import 'react-impressjs/styles/react-impressjs.css';
import DigitalMe from '../../../shared/DigitalMe.png';
import styles from '../HomePage/HomePage.module.scss';
import styles2 from '../../../css/StackedCard.module.scss';
import PreferencesTab from '../PreferencesTab/PreferencesTab';
import $ from 'jquery';

class WebsiteIntroduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarColor:"#2c3e50"
    };
  }
  componentDidMount(){
    $( document ).ready(function() {
      function animation(stepId,animateClass){
          $('#'+stepId).on('impress:stepenter', function(){
               var step = $(this);
               step.find('div > div > div > div > div > p').addClass('animated '+animateClass);
          });                
          $('#'+stepId).on('impress:stepleave', function(){
               var step = $(this);
               step.find('i.fa').removeClass('animated '+animateClass);
          });
      };
          animation('intro','shake');  
  });
  }

  IntroductionComplete(color) {
    localStorage.setItem("firstVisit", "false");
    this.props.SavePrefs(color);
  }

  setSideBarColor = (event) => {
    this.setState ({
      ...this.state,
      sideBarColor: event.target.value
    });
 }

  render() {
    return (
      <Impress fallbackMessage={<p>Sorry, your <b>device or browser</b> couldn't support well.</p>}>
        {/* <Step id={'overview'} data={{ x: 100, y: -100, scale: 2 }}/> */}
        <Step duration={1500} data={{ x: -500, y: -500, scale: 1 }}>
          <h1 style={{ whiteSpace: `nowrap`, overflow: `hidden`, width: 0, opacity: 0, WebkitUserSelect: `none`,
            userSelect: `none`, animation: `type 2s steps(60, end) forwards`}}>Hey You!</h1>
          <p style={{opacity: 0,animation: `fadein 2s forwards`, animationDelay: `2s` }}>Yes, <b>YOU</b> !!</p>
          {/* Jarvis? */}
        </Step>

        <Step duration={1500} data={{ x: 1500, y: 1500, scale: 1 }}>
        <div className={styles2.content + " card mb-3 groove-hover"} style={{ padding: `2% 3%`, border: `3px solid`, 
              background: 'radial-gradient(ellipse at bottom, rgba(5, 39, 103,0.9) 0%, ' + this.props.sideBarColor + 'E6' + ' 70%)',
              margin: '10px', flexWrap: `wrap`, alignContent: `flex-end`
            }}>
            <div className={`${styles.block}`} style={{ width: `30%` }}>
              <div className={`${styles.side} ${styles.mainn}`}></div>
              <div className={`${styles.side} ${styles.leftt}`}></div>
            </div>
          <div class="col-md-8" style={{ width: `70%` }}>
            <h1>Welcome to my website!</h1>
            <p>Come on in...</p>
          </div>
          </div>
        </Step>

        <Step id="intro" duration={1500} data={{ x: 3500, y: 3500, scale: 1 }}>
          <div className={styles2.stackedDown}>
            <div className={styles2.content + " card mb-3 groove-hover"} style={{
              padding: `2% 3%`, border: `3px solid`,
              background: 'radial-gradient(ellipse at bottom, rgba(5, 39, 103,0.9) 0%, ' + this.props.sideBarColor + 'E6' + ' 70%)', margin: '10px'
            }}>
              <div class="row g-0" style={{ borderRight: `dotted`, borderLeft: `dotted`, borderStyle: `dotted` }}>
                <div class="col-md-8" style={{ width: `100%` }}>
                  <div class="card-body" style={{ fontSize: `1.25rem`, position: `relative`, paddingBottom: 0, height: `100%`, height: this.state.cardHeight }}>
                    <h5 class="card-title" style={{ fontFamily: `fantasy` }}>&lt;Some_Website_Introduction&gt;</h5>
                    <p className={"scrollbar"} id="style-15" style={{ maxHeight: `84%`, fontFamily: `cursive`, display: `inline-block`, animationDelay:`0s`}}>
                      Hallo, Hello, Hola, Hej, Ciao!
                <br /> ......<br />
                This is supposed to be the introduction to Roys website, but he's as usual too busy trying doing things without me involved.. <br />
                Efin, let me introduce him for you: <br />
                21 years old young adult who likes to dance, survival, martial arts, skate, do ball sports and swim.
                When he’s too tired from all these things he goes to reading, drawing, programming and occasional gaming.<br /><br />
                Whenever he gets going without me I make sure to have him addicted to music, even when he’s not actually listening.
                <img src={DigitalMe} style={{ backgroundColor: `white`, float: `right` }}
                        className="rounded-circle center-image" width="140" height="140" alt="This is a placeholder"></img>
                Tiring right? Wait, you're still reading? Actually interested? <br />Alright then..
                Hope you find what you look for on this website made by us. Maybe we will even see you soon!
                <br />
                      <p class="card-text" style={{ bottom: 0 }}>
                        <h5 style={{ margin: `0` }}> ~ Jarvis (Roy's mind head officer)</h5>
                      </p>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Step>

        <Step duration={1500} data={{ x: 5000, y: 5000, scale: 1 }}>
          <h1>One final thing..</h1> 
          <h3>What is your absolute favorite color?</h3>
          <input type="color" id="favcolor" name="favcolor" value={this.state.sideBarColor} onChange={this.setSideBarColor}/>
          <button onClick={() => this.IntroductionComplete(this.state.sideBarColor)}>Submit</button>
        </Step>
      </Impress>
    );
  }
}
export default WebsiteIntroduction;