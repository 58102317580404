import React from 'react';
import styles from "./PreferencesTab.module.css"
import Slider from "react-slick";
import CardContainer from '../../../shared/classComponents/CardContainer/CardContainer';

class BackgroundPreferencesTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    isSelected(name) {
        if (this.props.selectedImage == name)
            return styles.active;
    }

    render() {
        const settings = {
            dots: true,
            speed: 500,
            slidesToShow: this.props.width < 768 ? 1 : 2,
            slidesToScroll: 1,
            rows: this.props.width < 768 ? 1 : 2
        };

        return (
            <CardContainer title='What background would you prefer?' instructions='Select a background which you would like to have'
                sideBarColor={this.props.sideBarColor} icon='fas fa-image' notflexablecontent={true}>
                <div style={{ textAlign: `center` }}>
                    <Slider {...settings}>
                        {/* <div className={styles.imageItem}>
                        <img className={styles.imageClass + " " + this.isSelected("bckgrnd2.png")} src={"bckgrnd2.png"} onClick={() => this.props.selectImage("bckgrnd2.gif")}></img>
                        <h6>Default</h6>
                    </div> */}
                        <div className={styles.imageItem}>
                            <img className={styles.imageClass + " " + this.isSelected("backgroundcity.gif")} src={"backgroundcity.gif"} onClick={() => this.props.selectImage("backgroundcity.gif")}></img>
                            <h6>City Night</h6>
                        </div>
                        <div className={styles.imageItem}>
                            <img className={styles.imageClass + " " + this.isSelected("backgroundcityblue.gif")} src={"backgroundcityblue.gif"} onClick={() => this.props.selectImage("backgroundcityblue.gif")}></img>
                            <h6>City Noon</h6>
                        </div>
                        <div className={styles.imageItem}>
                            <img className={styles.imageClass + " " + this.isSelected("backgroundjungle.gif")} src={"backgroundjungle.gif"} onClick={() => this.props.selectImage("backgroundjungle.gif")}></img>
                            <h6>Jungle</h6>
                        </div>
                        <div className={styles.imageItem} >
                            <img className={styles.imageClass + " " + this.isSelected("SpaceBackgroundGIF.gif")} src={"SpaceBackgroundGIF.gif"} onClick={() => this.props.selectImage("SpaceBackgroundGIF.gif")}></img>
                            <h6>Space</h6>
                        </div>
                        <div className={styles.imageItem}>
                            <img className={styles.imageClass + " " + this.isSelected("backgroundmatrix.gif")} src={"backgroundmatrix.gif"} onClick={() => this.props.selectImage("backgroundmatrix.gif")}></img>
                            <h6>Matrix</h6>
                        </div>
                        <div className={styles.imageItem}>
                            <img className={styles.imageClass + " " + this.isSelected("background8.gif")} src={"background8.gif"} onClick={() => this.props.selectImage("background8.gif")}></img>
                            <h6>Flows</h6>
                        </div>
                    </Slider>
                </div>
            </CardContainer>
        )
    }
}
export default BackgroundPreferencesTab;