import React from 'react';
import ArtPageDataService from '../../../services/ArtPage.service';

class ArtCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DefaultImage: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22288%22%20height%3D%22225%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20288%20225%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1787ee88258%20text%20%7B%20fill%3A%23eceeef%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1787ee88258%22%3E%3Crect%20width%3D%22288%22%20height%3D%22225%22%20fill%3D%22%2355595c%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2296.8203125%22%20y%3D%22118.74375%22%3EThumbnail%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
      Likes: this.getLikesFromLocalStorage()
    };
    this.canUpdate = true;
  }
  getLikesFromLocalStorage() {
    var storageLikes = localStorage.getItem('likes');
    if (storageLikes === undefined || storageLikes === "" || storageLikes == null) {
      storageLikes = JSON.stringify([{ source: "" }])
      localStorage.setItem('likes', storageLikes)
    }

    return JSON.parse(storageLikes)
  }

  updateLike(source) {
    if (!this.canUpdate) return
    //disable
    //onsetstate, after send call.
    var likes = this.getLikesFromLocalStorage()
    var liked = this.isLiked(likes, source)
    if (!liked) {
      this.canUpdate = false;
      ArtPageDataService.increment({ source: source, name: "likes" })
        .then(response => {
          setTimeout(() => {
            likes.push({ source: source })
            localStorage.setItem("likes", JSON.stringify(likes))
            this.setState({ ...this.state, Likes: likes }, () => {
              this.canUpdate = true
              this.props.retrieveArtCardData()
            })
          }, 500)
        })
        .catch(e => {
          console.log(e);
        });
    }

    else {
      this.canUpdate = false;
      ArtPageDataService.decrease({ source: source, name: "likes" })
        .then(response => {
          setTimeout(() => {
            const index = likes.indexOf(liked);
            if (index > -1) {
              likes.splice(index, 1);
              localStorage.setItem("likes", JSON.stringify(likes))
              this.setState({ ...this.state, Likes: likes }, () => {
                this.canUpdate = true
                this.props.retrieveArtCardData()
              })
            }
          }, 500)
        })
    }
  }

  isLiked(likes, source) {
    if (likes === null) return false;

    return likes.find((value) => {
      if (value == undefined || value == null) {
        return false
      }
      if (value.source == source) {
        return true
      }
      return false
    });
  }

  getLikedClass(likes, source) {
    if (this.isLiked(likes, source)) {
      return "liked";
    }

    else {
      return "notliked";
    }
  }

  render() {
    const content = [];
    if (this.props.displayMode === "image") {
      content.push(<img class="card-img-top" data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
        alt="Thumbnail [100%x225]" style={{ display: `block`, height: `calc(var(--doc-height)*.3)` }}
        src={this.props.source != null ? process.env.PUBLIC_URL + this.props.source : this.state.DefaultImage}
        data-holder-rendered="true" />)
    } else content.push(<model-viewer src={this.props.source != null ? process.env.PUBLIC_URL + this.props.source : this.state.DefaultImage}
      exposure="1" shadow-intensity="6.7" shadow-softness="1" auto-rotate style={{ height: `calc(var(--doc-height)*.3)`, width: `100%`, display: `block` }} camera-controls />);
    return (
      <div class="card box-shadow" style={{ margin: `1%`, backgroundColor: this.props.sideBarColor + 90 }}>
        <div className="card-content">
          {content}
        </div>
        <div class="card-body">
          <p class="card-text mb-0">{this.props.description}</p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="btn-group">
              <span className={this.getLikedClass(this.state.Likes, this.props.source)} onClick={() => this.updateLike(this.props.source)} style={{ cursor: `pointer` }}>
                <i className={"fas fa-heart"} ></i>
              </span>
              &nbsp;{this.props.likes}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ArtCard;