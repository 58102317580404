import React from 'react';
import Footbalfield3D from '../../../Footbalfield3D.glb';
import ArtCard from '../../../shared/classComponents/ArtCard/ArtCard';
import ArtPageDataService from '../../../services/ArtPage.service';
import ArtHeader from '../../../shared/classComponents/ArtHeader/ArtHeader';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faCubes, faPlusSquare, faMinusSquare, faStopCircle, faPlayCircle, faSquare, faThLarge } from '@fortawesome/free-solid-svg-icons'
const logo = require('../../../shared/grain.png');

class ArtPage extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();

    this.state = {
      ArtCardData: [],
      isActive: false,
      index: 0,
      slidesToShowImages: 3,
      slidesToShowModel: 1,
      autoplayImages: true,
      autoplayModels: false,
      rowsImages: 1
    };
    this.main = React.createRef();
    this.retrieveArtCardData = this.retrieveArtCardData.bind(this);
    this.setActive = this.setActive.bind(this)
    this.sliderRef = React.createRef();
  }

  setActive(mutations, mut) {
    this.setState({ ...this.state, isActive: !this.isActive })
  }

  componentDidMount() {
    var mut = new MutationObserver(this.setActive);
    mut.observe(document.querySelector("#art"), {
      'attributes': true
    });

    this.retrieveArtCardData();
    this.props.changeBackgroundImage();
  }
  retrieveArtCardData() {
    ArtPageDataService.getAll()
      .then(response => {
        this.setState({
          ArtCardData: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  onWheel = (e) => {
    const containerScrollPosition = this.containerRef.current.scrollLeft;
    this.containerRef.current.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: "smooth"
    });
  }

  componentDidUpdate() {
    // console.log("Art page rerendered")
  }

  goToSlide = (index) => {
    this.setState({ ...this.state, index: index },
      () => this.sliderRef.current.slickGoTo(index))
  };

  setSlidesToShow(number) {
    if (this.state.index == 0) {
      this.setState({ ...this.state, slidesToShowImages: this.state.slidesToShowImages + number })
    } else {
      this.setState({ ...this.state, slidesToShowModel: this.state.slidesToShowModel + number })
    }
  }

  toggleAutoPlay() {
    if (this.state.index == 0) {
      this.setState({ ...this.state, autoplayImages: !this.state.autoplayImages })
    } else {
      this.setState({ ...this.state, autoplayModels: !this.state.autoplayModels })
    }
  }

  toggleRows() {
    if (this.state.index == 0) {
      var rows = 1
      if (this.state.rowsImages == 1) rows = 2
      this.setState({ ...this.state, rowsImages: rows })

    }
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.width < 768 ? 1 : this.state.slidesToShowModel,
      slidesToScroll: 1,
      draggable: false,
      swipe: false
    };

    const overallSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props.width < 768 ? 1 : 1,
      slidesToScroll: 1,
      draggable: false,
      autoplay: this.state.autoplayModels
    };

    const artSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props.width < 768 ? 1 : this.state.slidesToShowImages,
      rows: this.props.width < 768 ? 1 : this.state.rowsImages,
      slidesToScroll: 1,
      draggable: this.props.width < 768 ? false : false,
      dots: this.props.width < 768 ? false : true,
      autoplay: this.state.autoplayImages
    }
    const artcardsImg = []
    const artcardsModel = []

    this.state.ArtCardData.forEach(element => {
      if (element.displayMode === "image") {
        var smallSource = element.source.replace(".jpg", "S.jpg")
        var source = this.state.isActive ? element.source : smallSource

        artcardsImg.push(
          <div class="col-md-4">
            <ArtCard
              _id={element._id}
              displayMode={element.displayMode}
              source={source}
              description={element.description}
              updatedAt={element.createdAt}
              likes={element.likes}
              sideBarColor={this.props.sideBarColor}
              retrieveArtCardData={this.retrieveArtCardData} />
          </div>)
      }
      else artcardsModel.push(
        <div class="col-md-4">
          <ArtCard
            _id={element._id}
            displayMode={element.displayMode}
            source={this.state.isActive ? element.source : null}
            description={element.description}
            updatedAt={element.createdAt}
            color={this.props.sideBarColor}
            likes={element.likes}
            sideBarColor={this.props.sideBarColor}
            retrieveArtCardData={this.retrieveArtCardData} />
        </div>)
    });

    return (
      <div className="container mobileContainer" ref={this.main}>
        <main role="main" style={{ borderRadius: `10px`, backgroundColor: this.props.sideBarColor + 'E6' }}>
          <Slider ref={this.sliderRef} {...overallSettings}>
            <>
              <div class="album py-3">
                <div class="container">
                  <Slider {...artSettings}> {artcardsImg} </Slider>
                </div>
              </div>
            </>
            <>
              <div class="album pb-5">
                <div class="container">
                  <div class="row">
                    <Slider {...settings}>
                      {artcardsModel}
                    </Slider>
                  </div>
                </div>
              </div>
            </>
          </Slider>
          <div className="pt-3" style={{padding: `1rem`, textAlign: `center`,
            display: `flex`, justifyContent: `space-evenly`
          }}>
            <div className='p-3 mb-3' style={{ border: `2px solid`, borderRadius: `10px` }}>
              {this.state.index == 0 ? <span style={{ cursor: `pointer` }} onClick={() => this.goToSlide(1)}><FontAwesomeIcon icon={faImages} size='lg' /></span> :
                <span style={{ cursor: `pointer` }} onClick={() => this.goToSlide(0)}><FontAwesomeIcon icon={faCubes} size='lg' /></span>}
            </div>
            {this.props.width < 768 ? <></> : <>
              <div className='p-3 mb-3' style={{ border: `2px solid`, borderRadius: `10px` }}>
                <span style={{ cursor: `pointer` }} onClick={() => {
                  if (this.state.slidesToShowImages <= 5 && this.state.index == 0 || this.state.slidesToShowModel < 3 && this.state.index == 1) this.setSlidesToShow(1)
                }}><FontAwesomeIcon icon={faPlusSquare} size='lg' /></span>
              </div>
              <div className='p-3 mb-3' style={{ border: `2px solid`, borderRadius: `10px` }}>
                {this.state.rowsImages == 1 ?
                  <span style={{ cursor: `pointer` }} onClick={() => this.toggleRows()} disabled={this.state.index != 0}><FontAwesomeIcon icon={faSquare} size='lg' /></span> :
                  <span style={{ cursor: `pointer` }} onClick={() => this.toggleRows()} disabled={this.state.index != 0}><FontAwesomeIcon icon={faThLarge} size='lg' /></span>}
              </div>
              <div className='p-3 mb-3' style={{ border: `2px solid`, borderRadius: `10px` }}>
                <span style={{ cursor: `pointer` }} onClick={() => {
                  if (this.state.slidesToShowImages > 1 && this.state.index == 0 || this.state.slidesToShowModel > 1 && this.state.index == 1)
                    this.setSlidesToShow(-1)
                }}><FontAwesomeIcon icon={faMinusSquare} size='lg' /></span>
              </div>
              <div className='p-3 mb-3' style={{ border: `2px solid`, borderRadius: `10px` }}>
                {(this.state.index == 0 && this.state.autoplayImages) || (this.state.index == 1 && this.state.autoplayModels) ?
                  <span style={{ cursor: `pointer` }} onClick={() => this.toggleAutoPlay()}><FontAwesomeIcon icon={faStopCircle} size='lg' /></span> :
                  <span style={{ cursor: `pointer` }} onClick={() => this.toggleAutoPlay()}><FontAwesomeIcon icon={faPlayCircle} size='lg' /></span>}
              </div>
            </>}
          </div>
        </main>
      </div>
    );
  }
}
export default ArtPage;