import React from 'react';
import CardContainer from '../../../shared/classComponents/CardContainer/CardContainer';

class ColorPreferencesTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CardContainer title='What color would you prefer?' instructions='Select a preset or choose specific colors which you would like to see'
        sideBarColor={this.props.sideBarColor} icon='fas fa-tint'>
        <div id="Presets" style={{ display: `flex`, justifyContent: `space-evenly`, textAlign: `center` }}>
          <div>
            <h6>Dark Red</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#3f1d28"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#845552"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>
          <div>
            <h6>Steel Gray</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#1F1C2C"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#928DAB"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Cool Brown</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#603813"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#b29f94"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Grade Grey</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#2c3e50"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#bdc3c7"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Deep Sea</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#2c3e50"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#4ca1af"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>
        </div>

        <div id="Presets2" style={{ display: `flex`, justifyContent: `space-evenly`, textAlign: `center` }}>
          <div>
            <h6>Deep Space</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#000000"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#434343"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>
          <div>
            <h6>Bubble Gum</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#33001b"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#ff0084"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Twitch</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#2a0845"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#6441A5"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Jungle</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#4b5f4c"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#506068"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>

          <div>
            <h6>Ultra Violet</h6>
            <input type="color" id="favcolor" name="favcolor" value={"#654ea3"} onClick={this.props.setSideBarColor} onChange={() => 0} />
            <input type="color" id="favcolor" name="favcolor" value={"#eaafc8"} onClick={this.props.setSideBarColor2} onChange={() => 0} />
          </div>
        </div>

        <div id="colorPickers" style={{ display: `flex`, justifyContent: `space-evenly`, textAlign: `center`, paddingTop: `.5rem` }}>
          <div id="firstColor">
            <h6>Main</h6>
            <input type="color" id="favcolor" name="favcolor" value={this.props.sideBarColor} onChange={this.props.setSideBarColor} />
          </div>
          <div id="secondColor">
            <h6>Secondary</h6>
            <input type="color" id="favcolor" name="favcolor" value={this.props.sideBarColor2} onChange={this.props.setSideBarColor2} />
          </div>
        </div>
      </CardContainer>
    )
  }
}

export default ColorPreferencesTab;