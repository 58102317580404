import React from 'react';
import StatisticsPageDataService from '../../../services/StatisticsPage.service';
import { lightOrDark } from '../../../shared/Utils';
import styles from "./StatisticsPage.module.css";

const logo = require('../../../shared/grain.png');

class StatisticsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageCountOptions: {
        options: {
          scales: {
            xAxes: [{
              ticks: {
                display: this.props.width < 756 ? false : true
              }
            }]
          }, legend: { display: this.props.width < 756 ? false : true }, font: { family: 'cursive' }, title: { display: true, text: 'Website page visits' }
        },
      },
      FitnessStatsOptions: {
        options: {
          responsive: true, tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItems, data) {
                var text = tooltipItems.datasetIndex === 0 ? tooltipItems.yLabel + 'Km' : tooltipItems.yLabel.toString().substr(0, 4) + " km/h"
                return text;
              }
            }
          }, interaction: { mode: 'index', intersect: true }, title: { display: true, text: 'Running' },
          scales: {
            xAxes: [{
              ticks: {
                display: this.props.width < 756 ? false : true
              }
            }],
            yAxes: [{ id: 'A', type: 'linear', position: 'left', ticks: { beginAtZero: true } }, {
              id: 'B', type: 'linear',
              ticks: {
                callback: function (value, index, ticks) {
                  return value + "km/h"
                },
                suggestedMin: 100,
                stepSize: .5,
                beginAtZero: true,
                min: 8
              },
              position: 'right'
            }]
          }, legend: { display: this.props.width < 756 ? false : true }
        }
      },
      fitnessMonthFilter: ""
    };
    this.filterMonth = this.filterMonth.bind(this)
  }

  componentDidMount() {
    var ctx = document.getElementById('myChart').getContext('2d');
    var ctx2 = document.getElementById('myChart2').getContext('2d');
    var ctx3 = document.getElementById('myChart3').getContext('2d');
    var ctx4 = document.getElementById('myChart4').getContext('2d');

    this.retrieveStatisticsData();
    this.retrieveLikesData();

    this.pageStats = new window.Chart(ctx, {
      type: "bar",
      data: {
        datasets: [
          {
            label: "Count",
            data: undefined,
            backgroundColor: [
              "#b92b27",
              "#1565C0",
              "#373B44",
              "#493240",
              "#1f4037",
              "#f4791f",
              "#C06C84",
              "#40E0D0"
            ]
          }
        ]
      }, ...this.state.PageCountOptions
    });

    this.colorStats = new window.Chart(ctx3, {
      type: "pie",
      data: {
        datasets: [
          {
            label: "Count",
            data: undefined,
            backgroundColor: [
              "#FF0000",
              "#FFA500",
              "#FFFF00",
              "#008000",
              "#0000FF",
              "#EE82EE",
              "#A52A2A",
              "#000000",
              "#808080",
              "#FFFFFF"
            ]
          }
        ]
      }, options: {
        legend: {
          display: this.props.width < 756 ? false : true
        },
        font: {
          family: 'cursive'
        },
        title: {
          display: true,
          text: 'Website color picks'
        },
      }
    });
    this.likeStats = new window.Chart(ctx4, {
      type: "pie",
      data: {
        datasets: [
          {
            label: "Count",
            data: undefined,
            backgroundColor: [
              "#FF0000",
              "#FFA500",
              "#FFFF00",
              "#008000",
              "#0000FF",
              "#EE82EE",
              "#A52A2A",
              "#000000",
              "#808080",
              "#FFFFFF"
            ]
          }
        ]
      }, options: {
        legend: {
          display: this.props.width < 756 ? false : true
        },
        font: {
          family: 'cursive'
        },
        title: {
          display: true,
          text: 'Top likes'
        },
      }
    });

    this.AddFitnessChart(ctx2)
  }

  AddFitnessChart(ctx2) {
    this.fitnessStats = new window.Chart(ctx2, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          type: 'bar',
          label: 'Km',
          yAxisID: 'A',
          data: [],
          backgroundColor: 'rgba(54, 162, 235, 0.4)',
          fill: true,
          lineTension: 0
        }, {
          type: 'line',
          label: 'Time',
          yAxisID: 'B',
          backgroundColor: 'rgba(255, 99, 132)',
          fill: true,
          steppedLine: true,
          data: [],
        }]
      }, ...this.state.FitnessStatsOptions
    });
  }

  retrieveLikesData() {
    StatisticsPageDataService.getTopLikes()
      .then(response => {
        var array = [];
        var arrayData = [];
        response.data.map((data) => {
          Object.keys(data).map((key) => {
            this.getLikesData(data, key, array, arrayData)
          })
        })
        this.removeData(this.likeStats);
        this.addData(this.likeStats, array, arrayData);
      })
  }

  retrieveStatisticsData() {
    StatisticsPageDataService.getAll()
      .then(response => {
        var array = [];
        var arrayData = [];
        var fitnessData = [];
        var colorData = [];
        var colors = [];

        response.data.map((data) => {
          Object.keys(data).map((key) => {
            if (data["type"] == "pageVisits") this.getPageVisitsData(data, key, array, arrayData)
            //This is a mess
            if (data["type"] == "fitnessData") {
              if (this.state.fitnessMonthFilter == "") this.getFitnessData(data, key, fitnessData)
              else if (data["Date"].substr(data["Date"].length - 1) == this.state.fitnessMonthFilter)
                this.getFitnessData(data, key, fitnessData)
            }
            if (data["type"] == "colorCount") {
              this.getColorCountData(data, key, colors, colorData)
            }
          })
        })
        this.removeData(this.pageStats);
        this.removeData(this.fitnessStats);
        this.removeData(this.colorStats);

        this.addData(this.pageStats, array, arrayData);
        this.addData(this.colorStats, colors, colorData);
        this.addFitnessData(this.fitnessStats, fitnessData);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getPageVisitsData(data, key, array, arrayData) {
    var x = key.toString();
    if (x == "_id" || x == "FavoriteColors" || x == "updatedAt" || x == "WebsiteVersion" || x == "type") { }
    else {
      array.push(x);
      arrayData.push(data[x])
    }
  }

  getColorCountData(data, key, array, arrayData) {
    var x = key.toString();
    if (x == "_id" || x == "type") { }
    else {
      array.push(x);
      arrayData.push(data[x])
    }
  }

  getLikesData(data, key, array, arrayData) {
    var x = key.toString();
    if (x != "likes") { }
    else {
      array.push(data["description"]);
      arrayData.push(data[x])
    }
  }

  getFitnessData(data, key, arrayData) {
    var x = key.toString();
    if (x == "_id" || x == "FavoriteColors" || x == "updatedAt" || x == "WebsiteVersion" || x == "type" || x == "__v") { }
    else {
      arrayData.push(data[x])
    }
  }

  addFitnessData(chart, data) {
    for (var i = 0; i < data.length; i++) {
      chart.data.labels.push(data[i]) // 3
      chart.data.datasets[0].data.push(parseFloat(data[i + 2])) //5
      chart.data.datasets[1].data.push(parseFloat(data[i + 2]) / (data[i + 1] / 3600)) //4
      i += 2
    }

    chart.update();
  }

  addData(chart, labels, data) {
    chart.data.labels = labels
    chart.data.datasets.forEach((dataset) => {
      dataset.data = data
    });
    chart.update();
  }

  removeData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    chart.update();
  }

  filterMonth(event) {
    this.setState({ ...this.state, fitnessMonthFilter: event.target.value }, () => {
      this.fitnessStats.destroy()
      var ctx2 = document.getElementById('myChart2').getContext('2d');
      this.retrieveStatisticsData()
      this.AddFitnessChart(ctx2)
    });
  }

  componentDidUpdate() {
    // console.log("Statistics page rerendered")
  }

  render() {
    return (
      <div className="container marketing pt-4">
        <div className="row" style={{ justifyContent: `center` }}>
          <div className={"text-center groove-hover " + styles.mobilePadding} style={{ borderRadius: `10px`, marginBottom: `1rem`, backgroundColor: this.props.sideBarColor + 'E6' }}>
            <canvas id="myChart" style={{ width: `80vw` }} />
          </div>
          <div className={"text-center groove-hover " + styles.mobilePadding} style={{display:`none`, borderRadius: `10px`, marginBottom: `1rem`, backgroundImage: 'url(' + logo.default + '),radial-gradient(ellipse at bottom, ' + this.props.sideBarColor2 + ' 0%, ' + this.props.sideBarColor + ' 70%)' }}>
            <select value={this.state.fitnessMonthFilter} onChange={this.filterMonth} style={{ background: `none` }}>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="">All months</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="1">January</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="2">February</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="3">March</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="4">April</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="5">May</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="6">June</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="7">July</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="8">August</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="9">September</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="10">October</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="11">November</option>
              <option style={{ backgroundColor: (this.props.sideBarColor) }} value="12">December</option>
            </select>
            <canvas id="myChart2" style={{ width: `80vw` }} />
          </div>
          <div className={"text-center groove-hover " + styles.mobileWith} style={{ marginRight: '10px', width: 'calc(50% - 11px)', borderRadius: `10px`, backgroundColor: this.props.sideBarColor + 'E6' }}>
            <canvas id="myChart3" style={{ width: `100%` }} />
          </div>
          <div className={"text-center groove-hover " + styles.mobileWith} style={{ marginLeft: '10px', width: 'calc(50% - 11px)', borderRadius: `10px`, backgroundColor: this.props.sideBarColor + 'E6' }}>
            <canvas id="myChart4" style={{ width: `100%` }} />
          </div>
        </div>
      </div>
    );
  }
}
export default StatisticsPage;