import React from 'react';
import styles from './BlogPage.module.css';
import WoT from "../../../shared/WoT.png";
import WoTS from "../../../shared/WoTS.png";
import FN from "../../../shared/Fn.png";
import FNS from "../../../shared/FnS.png";

import GO from "../../../shared/go.png";
import Minecraft from "../../../shared/Minecraft.png";
import GTA from "../../../shared/GTA.png";
import marioK from "../../../shared/mariokart.png";
import marioKS from "../../../shared/mariokartS.png";
import Slider from "react-slick";

import WoTL from "../../../shared/WoTL.png"
import FNL from "../../../shared/FNL.png"
import GOL from "../../../shared/GOL.png"
import MKL from "../../../shared/MKL.png"
import MCL from "../../../shared/MCL.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'
import WoTSlide from './WoTSlide';
import FNSlide from './FNSlide';
const logo = require('../../../shared/grain.png');

class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      index: 0
    };
    this.setActive = this.setActive.bind(this)
    this.sliderRef = React.createRef();

  }

  componentDidMount() {
    var mut = new MutationObserver(this.setActive);
    mut.observe(document.querySelector("#blog"), {
      'attributes': true
    });
  }

  componentDidUpdate() {
    // console.log("Game page rerendered")
  }

  setActive(mutations, mut) {
    this.setState({ ...this.state, isActive: !this.isActive })
  }

  goToSlide = (index) => {
    this.sliderRef.current.slickGoTo(index);
  };

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props.width < 768 ? 1 : 1,
      slidesToScroll: 1,
      draggable: false,
      swipe: true
    };

    return (
      <div class="container" style={{
        border: `1rem solid`, borderColor: `black`, borderRadius: `10px `, padding: 0,
        backgroundColor: this.props.sideBarColor + 'E6'
      }}>
        <div style={{ minHeight: `calc(var(--doc-height)*.3)`, maxHeight: `calc(var(--doc-height)*.6)`, textAlign: `center` }}>
          <Slider ref={this.sliderRef} {...settings}>
            <WoTSlide />
            <FNSlide />
          </Slider>
        </div>
        <div style={{ backgroundColor: this.props.sideBarColor, paddingLeft: 0, padding: `2px`, display: `flex`, justifyContent: `center` }}>
          <div className={styles.barItem} style={{ display: `flex`, height: `67px` }}>
            <img onClick={() => this.goToSlide(0)} style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={WoTL} className={"center-image"} alt="Image" height="40" width="40"></img>
            <img onClick={() => this.goToSlide(1)} style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={FNL} className={"center-image"} alt="Image" height="40" width="40"></img>
            <img style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={GOL} className={"center-image"} alt="Image" height="40" width="40"></img>
            <img style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={MCL} className={"center-image"} alt="Image" height="40" width="40"></img>
            <img style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={MKL} className={"center-image"} alt="Image" height="40" width="40"></img>
            <img style={{ marginLeft: `.5rem`, margin: `.5rem 0 .5rem .5rem` }} src={GTA} className={"center-image"} alt="Image" height="40" width="40"></img>
          </div>
        </div>
      </div>
    );
  }
}
export default BlogPage;