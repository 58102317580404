import http from "../http-common";

class StatisticsPageDataService {
  getAll() {
    return http.get("/statisticspage");
  }

  get(id) {
    return http.get(`/statisticspage/${id}`);
  }

  create(data) {
    return http.post("/statisticspage", data);
  }

  createFitnessDocument(data){
    return http.post("/statisticspage/fitnessdocument", data)
  }
  
  increment(data){
    return http.put(`/statisticspage/increment`, data);
  }

  incrementColor(data){
    return http.put(`/statisticspage/incrementColor`, data);
  }

  getTopLikes(){
    return http.get(`/artpage/topLikes`)
  }

  getBuildInfo(){
    return http.get(`/statisticspage/buildInfo`)
  }

  update(data) {
    return http.put(`/statisticspage`, data);
  }

  delete(id) {
    return http.delete(`/statisticspage/${id}`);
  }

  deleteAll() {
    return http.delete(`/statisticspage`);
  }

  findByTitle(title) {
    return http.get(`/statisticspage?title=${title}`);
  }
}
export default new StatisticsPageDataService();
