import React from 'react';
import styles from "./PreferencesTab.module.css"
import CardContainer from '../../../shared/classComponents/CardContainer/CardContainer';

class ContentTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shownPages: this.props.shownPages,
      activeTab: 0
    }
    this.mapping = {
      "/": "Home",
      "/about": "About",
      "/skills": "Skills",
      "/hobbies": "Hobby",
      "/music": "Music",
      "/art": "Art",
      "/games": "Games",
      "/statistics": "Statistics"
    }

    this.setShownPages = this.setShownPages.bind(this);
  }
  componentDidUpdate() {
    console.log(this.state.shownPages)
  }

  selectItem(name) {
    var showPages = ""
    if (name == "work") showPages = "/,/about, /skills, /statistics"
    else if (name == "personal") showPages = "/, /about, /music, /art, /games, /statistics"

    this.setState({ ...this.state, activeTab: name, shownPages: showPages }, () => {
      this.props.setWebsiteView(showPages)
    })
  }

  setShownPages(name) {
    var newShownPages = this.state.shownPages;
    if (newShownPages.includes(name)) {
      newShownPages = newShownPages.replace(name, "")
    } else {
      newShownPages += name
    }
    this.setState({ ...this.state, shownPages: newShownPages }, () => {
      this.props.setWebsiteView(newShownPages)
    })
  }

  //merge with welcome tab
  //Cookie melding?
  //Bong cat gif
  //Blocks
  render() {
    return (
      <>
        <CardContainer title='What would you like to see?' instructions='Select a preset or choose specific topics which you would like to see'
          sideBarColor={this.props.sideBarColor} icon='fas fa-file'>
          <div className='d-flex' style={{ flexDirection: `row`, alignItems: `center`, width: `100%`, textAlign: `center` }}>
            <div className={styles.selectItem} style={{
              flex: `1`, padding: `10%`, marginRight: `5%`,
              border: (this.state.activeTab == "work" ? `1.5px solid` : `.5px solid`), borderColor: this.props.sideBarColor2
            }}
              onClick={() => this.selectItem("work")}><i className={"fas fa-laptop"} ></i><br />Work</div>
            <div className={styles.selectItem} style={{
              flex: `1`, padding: `10%`,
              border: (this.state.activeTab == "personal" ? `1.5px solid` : `.5px solid`), borderColor: this.props.sideBarColor2
            }}
              onClick={() => this.selectItem("personal")}><i className={"fas fa-user"} ></i><br />Personal</div>
          </div>
          <br /><br />
          <h4>Visible pages</h4>
          <div className='d-flex' style={{ marginTop: `1rem`, flexDirection: `row`, alignItems: `center`, width: `100%`, textAlign: `center`, justifyContent: `space-evenly` }}>
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/" />
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/skills" />
            {/* <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/hobbies" /> */}
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/music" />
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/art" />
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/games" />
            <PageCheckBox setShownPages={this.setShownPages} shownPages={this.state.shownPages} name="/statistics" />
          </div>
        </CardContainer>      
      </>
    )
  }
}
class PageCheckBox extends React.Component {
  constructor(props) {
    super(props)

    this.mapping = {
      "/": "oi fas fa-home",
      "/about": "oi fas fa-id-card",
      "/skills": "oi fas fa-laptop",
      "/hobbies": "oi fas fa-image",
      "/music": "oi fas fa-music",
      "/art": "oi fas fa-palette",
      "/games": "oi fas fa-gamepad",
      "/statistics": "oi fas fa-chart-line"
    }
  }

  componentDidUpdate() {
    console.log("pagecheckbox updated")
  }

  render() {
    return (
      <div className={styles.selectItem + " " + (this.props.shownPages.includes(this.props.name) ? styles.active : "")} style={{ padding: `1rem` }}
        onClick={() => this.props.setShownPages(this.props.name)}>
        <i className={this.mapping[this.props.name]} />
      </div>
    )
  }
}

export default ContentTab;