import React from 'react';
import styles from '../../../pages/components/HomePage/HomePage.module.scss';
import { Link } from 'react-scroll';
const logo = require('../../../shared/grain.png');

const PresentCard = (props) => (
  // "++"
  <div className={styles[props.style] + " PresentCardWidth"} style={{ flexShrink: 1, flexDirection: `row` }}>
    <div className={styles[props.style]} style={{
      background: `none`
    }}>
      <div className={"text-center"} style={{display:`flex`}}>
        {props.image == "" ? <></>
          : <img src={props.image} className="rounded-circle center-image" width="140" height="140" alt="This is a placeholder"></img>}
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <h1 key={props.title} style={{ animation: `fade-text-in 1s 0s forwards`, fontWeight:`800` }}>{props.showText ? props.title : ""}</h1>
          <p className={(props.showLink ? "" : "m-0")}>
            {props.showText ? props.text : ""}
          </p>
          {props.showLink ? <p> <></></p> :<></>}
        </div>
      </div>
    </div>
  </div>
);

PresentCard.defaultProps = {
  title: 'Hallo, Hello, Hola, Hej, Ciao!',
  text: 'this is text',
  showText: '',
  link: '/',
  sideBarColor: '',
  sideBarColor2: '',
  image: '',
  type: '',
  style: '',
  showLink: true,
  animation: false
};

export default PresentCard;
