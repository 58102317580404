import React from 'react';
import styles from "./PreferencesTab.module.css"
import Slider from "react-slick";
import CardContainer from '../../../shared/classComponents/CardContainer/CardContainer';

class ThemesTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    isSelected(name) {
        if (this.props.selectedImage == name)
            return styles.active;
    }

    render() {
        const settings = {
            dots: true,
            speed: 500,
            slidesToShow: this.props.width < 768 ? 1 : 2,
            slidesToScroll: 1,
            rows: this.props.width < 768 ? 1 : 2
        };

        return (
            <CardContainer title='Classic themes' instructions='Select a theme which you would like to have'
                sideBarColor={this.props.sideBarColor} icon='fas fa-image' notflexablecontent={true}>
                <div style={{ textAlign: `-webkit-center` }}>
                    <Slider {...settings}>
                        <>
                            <div className={styles.imageItem + " " + styles.imageClass} onClick={() => this.props.setTheme("#3f1d28", "#845552", "backgroundcity.gif")}>
                                <img src={"backgroundcity.gif"}></img>
                                <input type="color" id="favcolor" name="favcolor" value={"#3f1d28"} onChange={() => 0} disabled />
                                <input type="color" id="favcolor" name="favcolor" value={"#845552"} onChange={() => 0} disabled />
                            </div>
                        </>
                        <>
                            <div className={styles.imageItem + " " + styles.imageClass} onClick={() => this.props.setTheme("#2c3e50", "#bdc3c7", "backgroundcityblue.gif")}>
                                <img src={"backgroundcityblue.gif"}></img>
                                <input type="color" id="favcolor" name="favcolor" value={"#2c3e50"} onChange={() => 0} disabled />
                                <input type="color" id="favcolor" name="favcolor" value={"#bdc3c7"} onChange={() => 0} disabled />
                            </div>
                        </>
                        <>
                            <div className={styles.imageItem + " " + styles.imageClass} onClick={() => this.props.setTheme("#000000", "#434343", "backgroundcitynight.gif")}>
                                <img src={"backgroundcitynight.gif"}></img>
                                <input type="color" id="favcolor" name="favcolor" value={"#000000"} onChange={() => 0} disabled />
                                <input type="color" id="favcolor" name="favcolor" value={"#434343"} onChange={() => 0} disabled />
                            </div>
                        </>
                        <>
                            <div className={styles.imageItem + " " + styles.imageClass} onClick={() => this.props.setTheme("#4b5f4c", "#506068", "backgroundjungle.gif")}>
                                <img src={"backgroundjungle.gif"}></img>
                                <input type="color" id="favcolor" name="favcolor" value={"#4b5f4c"} onChange={() => 0} disabled />
                                <input type="color" id="favcolor" name="favcolor" value={"#506068"} onChange={() => 0} disabled />
                            </div>
                        </>
                    </Slider>
                </div >
            </CardContainer>
        )
    }
}
export default ThemesTab;