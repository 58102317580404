import React from 'react';
import StatisticsPageDataService from '../../../services/StatisticsPage.service';
import Album from '../../../shared/classComponents/Album/Album';
import backgroundSpeaker from '../../../shared/backgroundspeaker.gif';
import backgroundSpeakerStatic from '../../../shared/backgroundspeakerstatic.png';
import http from "../../../../src/http-common";
import axios from "axios";
import styles from './MusicPage.module.scss';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons'

const logo = require('../../../shared/grain.png');

//ToDo implement chaching or transport calls to back-end
class MusicPage extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();

    this.state = {
      clientId: "7a0401d4e94e4eb6a255ecb08054f175",
      clientSecret: "8aad1add5f074b88b793adf5ca700ccb",
      redirectURI: "http://localhost:8081/music",
      albums: "",
      songs: "",
      code: "",
      grant_type: "",
      access_token: "",
      selectedAlbumId: "",
      isPlaying: false,
      playedSong: "7makk4oTQel546B0PZlDM5",
      embedController: "",
      seconds: 0,
      hoveredIndex: -1
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.albumRef = React.createRef();
    this.sliderRef = React.createRef();
  }

  async componentDidMount() {
    this.props.changeBackgroundImage();
    this.getAccesToken().then(login => {
      var access_token = login.data.access_token;
      this.setState({ ...this.state, access_token: access_token });

      this.fetchAlbums(access_token).then(res => {
        var items = res.data.items;
        this.setState({ ...this.state, albums: items });
        this.fetchSongsOfAlbum(access_token, items);
      });
    });

    window.onSpotifyIframeApiReady = (IFrameAPI) => this.CreateController(IFrameAPI)
  }

  fetchSongsOfAlbum(access_token, items) {
    if (!items) return;
    var fetchSongsOfAlbum = this.state.selectedAlbumId != "" ? this.state.selectedAlbumId : items[0].id
    this.fetchSongs(access_token, fetchSongsOfAlbum).then(resp => {
      var songs = resp.data.items;
      var array = [];
      songs.forEach(element => {
        array.push(element.track);
      });
      this.setState({ ...this.state, songs: array });
    })
  }

  setFetchOptions(url, access_token) {
    return {
      url: url,
      method: 'GET',
      headers: { Authorization: `Bearer ${access_token}` },
      data: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  }

  UserLogin() {
    var scopes = 'user-read-private user-read-email';
    return http.get("https://accounts.spotify.com/authorize" + '?response_type=code' + '&client_id=' + this.state.clientId
      + (scopes ? '&scope=' + encodeURIComponent(scopes) : '') + '&redirect_uri=' + encodeURIComponent(this.state.redirectURI), {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  getUserAccesToken() {
    return http.get(`https://accounts.spotify.com/api/token`, {
      data: {
        "grant_type": "client_credentials",
        "code": this.state.code,
        "redirect_uri": "http://localhost:8081/music",
        "client_secret": this.state.clientSecret,
        "client_id": this.state.clientId,
      }
    })
  }

  async getAccesToken() {
    var b = new Buffer.from(this.state.clientId + ":" + this.state.clientSecret);
    b.toString('base64');
    let options = {
      url: 'https://accounts.spotify.com/api/token',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + b.toString('base64')
      },
      data: 'grant_type=client_credentials'
    }
    return axios(options)
      .then((resp) => {
        return resp;
      }).catch((err) => { console.log('ERR GETTING SPOTIFY ACCESS TOKEN', err); });
  }

  fetchAlbums = async (access_token) => {
    let options = this.setFetchOptions('https://api.spotify.com/v1/users/4n3szfeij4q5h6qzno1uqzlxo/playlists', access_token);
    return axios(options)
      .then((resp) => {
        return resp;
      }).catch((err) => { console.log('ERR GETTING ALBUMS', err); });
  }

  fetchSongs = async (access_token, playlist_id) => {
    let options = this.setFetchOptions(`https://api.spotify.com/v1/playlists/${playlist_id}/tracks`, access_token);
    return axios(options)
      .then((resp) => {
        return resp;
      }).catch((err) => { console.log('ERR GETTING SONGS', err); });
  }

  doHover(index) {
    this.setState({ ...this.state, hoveredIndex: index })
  }
  stopHover() {
    this.setState({ ...this.state, hoveredIndex: -1 })
  }

  CreateController(IFrameAPI) {
    console.log("Ready!")
    const spotify = window.Spotify;
    let element = document.getElementById('embed-iframe');
    let options = {
      height: '100px',
      uri: 'spotify:track:' + this.state.playedSong,
      backgroundColor: this.props.sideBarColor
    };

    IFrameAPI.createController(element, options, this.callback);
  };

  RenderSongs() {
    var SongElements = [];
    var index = 1;
    var clone = this.state.songs.map((obj, i) => ({ ...obj, index: i + 1 }))
    if (clone.length > 0) {
      clone.forEach(item => {
        SongElements.push(<tr onMouseOver={() => this.doHover(item.index)} onMouseLeave={() => this.stopHover()} className={styles.onHover}>
          <td>{this.state.hoveredIndex == item.index ?
            <a className="nav-link" style={{ mixBlendMode: `unset`, cursor: `pointer`, padding: 0 }}
              onClick={() => this.switchPlayedSong(item.id)}> <i className={"oi fas fa-play-circle"}></i></a> : item.index}</td>
          <td>{item.name}</td>
          <td className={styles.showMobile}>{item.album.name.slice(0, item.name.length)}</td>
          <td className={styles.showMobile}>{new Date(item.duration_ms * 1).toISOString().substr(14, 5)}</td>
        </tr>);
        index++;
      });
    }
    return SongElements;
  }

  RenderMyPlaylists() {
    var Playlists = [];
    if (this.state.albums.length > 0) {
      this.state.albums.forEach(item => {
        Playlists.push(<Album id={item.id} coverImage={item.images[0]?.url} onAlbumSelect={this.onAlbumSelect} name={this.getAlbumName(item.id)} />);
      });
    }
    return Playlists;
  }

  switchPlayedSong = (id) => {
    this.setState({ ...this.state, playedSong: id }, () => {
      if (this.state.embedController != "")
        this.playSong(this.state.embedController)
    })
  }

  onWheel = (e) => {
    const containerScrollPosition = this.containerRef.current.scrollLeft;
    this.containerRef.current.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: "smooth"
    });
  }

  onAlbumSelect = (id) => {
    this.setState({ ...this.state, selectedAlbumId: id },
      () => this.fetchSongsOfAlbum(this.state.access_token, this.state.albums))
    this.goToSlide(0)
  }

  getAlbumName(id) {
    if (!this.state.albums) return;
    var album = this.state.albums.find(album => album.id === id)
    return album?.name ?? "Playlist"
  }

  callback = (EmbedController) => {
    this.setState({ ...this.state, embedController: EmbedController })
  };

  playSong = (EmbedController) => {
    EmbedController.loadUri('spotify:track:' + this.state.playedSong)
    EmbedController.togglePlay()
    this.startTimer()
  }

  startTimer() {
    clearInterval(this.timer);
    this.setState({ ...this.state, seconds: 30 }, () => {
      if (this.timer >= 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
        this.setState({ ...this.state, isPlaying: true })
      }
    })
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      this.setState({ ...this.state, isPlaying: false })
      clearInterval(this.timer);
    }
  }

  componentDidUpdate() {
    console.log("Music page rerendered")


  }

  goToSlide = (index) => {
    this.sliderRef.current.slickGoTo(index);
  };

  render() {

    const settings = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      centerMode: false,
      initialSlide: 0,
    };

    return (
      <div className="container mobileContainer" style={{ borderRadius: `10px`, backgroundColor: this.props.sideBarColor + 'E6' }} ref={this.albumRef}>
        <section className={"d-flex"} style={{
          alignItems: `center`,
          justifyContent: `center`,
        }}>
          <div className="gallery">
            <div className="gallery-container">

              {/* <img className={styles.hideOnMobile + " gallery-item gallery-item-1"} src={this.state.isPlaying ? backgroundSpeaker : backgroundSpeakerStatic} data-index="1"
                style={{ borderRadius: `10px`, padding: `2rem`, background: 'black' }} /> */}

              <div className={"gallery-item gallery-item-3 "} style={{
                height: `calc(var(--doc-height)*.4 + 31px)`, width: `-webkit-fill-available`, top: `-31px`, borderTopLeftRadius: `10px`, borderTopRightRadius: `10px`,
                backgroundColor: this.props.sideBarColor + 'E6'
              }}>
                <div style={{
                  position: `relative`, backgroundColor: this.props.sideBarColor, borderBottom: `2px solid`,
                  borderTopLeftRadius: `10px`, borderTopRightRadius: `10px`, display: `flex`, justifyContent: `space-evenly`, padding: `.1rem`
                }} >
                  
                  <span data-title="Songs" style={{ cursor: `pointer` }} onClick={() => this.goToSlide(0)}><i className={"fas fa-table fa-lg"} ></i></span>
                  <span data-title="Playlists" style={{ cursor: `pointer` }} onClick={() => this.goToSlide(1)}><i className={"fas fa-compact-disc fa-lg"}></i></span>
                  <span data-title="Spotify profile"><a href="https://open.spotify.com/user/4n3szfeij4q5h6qzno1uqzlxo" target='_blank'>
                    <FontAwesomeIcon icon={faSpotify} size='lg' />
                  </a></span>
                  <span data-title="Settings" style={{ cursor: `pointer` }} onClick={() => this.goToSlide(2)}><i className={"fas fa-sliders-h fa-lg"}></i></span>

                </div>
                <Slider ref={this.sliderRef} {...settings}>
                  <div className='d-flex'>
                    <div className={styles.centerTable} style={{ paddingBottom: 0, borderRadius: `10px`, flex: `1`, justifyContent: `center` }}>
                      <table id="style-15" style={{ display: `block`, overflow: `auto`, width: `100%`, height: `calc(var(--doc-height)*.4 - 31px)`, marginBottom: `1rem`, borderCollapse: 'seperate', marginLeft: `5px` }}>
                        <thead>
                          <tr>
                            <td style={{ position: `sticky`, top: 0, backgroundColor: this.props.sideBarColor, zIndex: `100` }}>#</td>
                            <td style={{ position: `sticky`, top: 0, backgroundColor: this.props.sideBarColor, zIndex: `100` }}>Title</td>
                            <td className={styles.showMobile} style={{ position: `sticky`, top: 0, backgroundColor: this.props.sideBarColor, zIndex: `100` }}>Album</td>
                            <td className={styles.showMobile} style={{ position: `sticky`, top: 0, backgroundColor: this.props.sideBarColor, zIndex: `100` }}>Duration</td>
                          </tr>
                        </thead>
                        <tbody style={{ overflowY: `scroll`, width: `100%` }}>
                          {this.state.songs.length > 0 ? this.RenderSongs() : ''}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <section className="d-flex" style={{
                    alignItems: `center`,
                    justifyContent: `center`
                  }}>
                    <div className="preview-container" >
                      <div id="style-16" ref={this.containerRef} onWheel={this.onWheel} className={"preview-block"} style={{
                        maxHeight: `calc(var(--doc-height)*.42 - 31px)`, display: `flex`, flexDirection: `column`, flexWrap: `wrap`, paddingBottom: `.5rem`,
                        justifyContent: `center`, overflowX: `scroll`, alignContent: `flex-start`, alignItems: `center`, overflowY: `hidden`
                      }}>
                        {this.state.albums.length > 0 ? this.RenderMyPlaylists() : ''}
                      </div>
                      <div className={styles.hideOnMobile + " preview-middle"}>
                        <div className="preview-text" style={{ backgroundColor: this.props.sideBarColor }}>Playlists</div>
                      </div>
                    </div>
                  </section>
                  <section className="d-flex align-items-center flex-column">
                    <h4>Sound Settings</h4>
                    <div style={{ padding: `.5rem`, textAlign: `center` }}>
                      <label for="mute" style={{ display: `block` }}>Mute</label>
                      <input type="checkbox" name="mute" />
                    </div>

                    <div style={{ padding: `.5rem`, textAlign: `center` }}>
                      <label for="mute" style={{ display: `block` }}>Volume</label>
                      <input type="range" min="0" max="100" value="50" class="volume-slider" id="myVolumeSlider" />
                    </div>
                  </section>
                </Slider>
                <div id="embed-iframe"></div>
              </div>
              {/* <img className={styles.hideOnMobile + " gallery-item gallery-item-5"} src={this.state.isPlaying ? backgroundSpeaker : backgroundSpeakerStatic} data-index="5"
                style={{ borderRadius: `10px`, padding: `2rem`, background: 'black' }} /> */}
            </div>
          </div>
        </section>

        <style>
          {`
            #style-16:hover::-webkit-scrollbar-thumb {
              background-color: ${this.props.sideBarColor}
            }
          `}
        </style>
      </div>
    );
  }
}
export default MusicPage;