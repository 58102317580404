import React from 'react';
import { ShowBooleanSwitch } from '../../../shared/Utils';
import styles from './SkillsPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faReact, faJs, faJava, faAws, faAngular, faMicrosoft, faPhp, faLaravel } from '@fortawesome/free-brands-svg-icons'
import { faDatabase, faUnlockAlt, faLock } from '@fortawesome/free-solid-svg-icons'
import Terminal from 'terminal-in-react';
import { lightOrDark } from '../../../shared/Utils';
import http from "../../../../src/http-common";

class SkillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.ShowBooleanSwitch = ShowBooleanSwitch.bind(this);
    this.state = {
      frozenScrollPosition: 0,
      focussed: false
    };

    this.hooperImages = this.importAll(require.context('../../../shared/hooper', false, /\.(png|jpe?g|svg|webp)$/));
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.unfreezeScroll = this.unfreezeScroll.bind(this);
    this.freezeScroll = this.freezeScroll.bind(this);
  }

  importAll(r) {
    let images = [];
    r.keys().forEach((item, index) => {
      images.push(r(item).default)
    });
    return images
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  freezeScroll() {
    if (!this.state.focussed) {
      this.setState({ ...this.state, frozenScrollPosition: window.scrollY, focussed: true }, () => {
        console.log(this.state.frozenScrollPosition);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${this.state.frozenScrollPosition}px`;
      });
    }
  }

  unfreezeScroll() {
    this.setState({ ...this.state, focussed: false }, () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo({
        top: this.state.frozenScrollPosition,
        left: 0,
        behavior: "auto",
      })
    })
  }

  handleClick(event) {
    if (event.keyCode == "13") {
      this.freezeScroll();
    } else {
      event.stopImmediatePropagation();
    }
  };

  handleKeyDown(event) {
    if (event.keyCode != "13") {
      event.stopImmediatePropagation();
    }
  }

  componentDidMount() {
    document.getElementsByClassName("hoXDqm")[0].innerHTML += "<span class='terminalTitle' style='zoom:.8'>C:&bsol;RoyGinsel&bsol;Skills</span> " +
      "<div style='margin-left: auto;align-self: center;padding-right:3%;' data-title='Type &quot;help&quot; to get started!'><i style='height:11px;width:11px;' class='fa fa-info-circle'></i></div>"

    const originalElement = document.querySelectorAll('.dZozyu')[0]; // Get your element by its ID
    const cloneElement = originalElement.cloneNode(true);
    const elements = document.querySelectorAll('.dZozyu');
    elements.forEach((element) => {
      element.addEventListener('keydown', this.handleClick);
      element.addEventListener('keypress', this.handleKeyDown);
    });
  }

  // componentWillUnmount() {
  //   const elements = document.querySelectorAll('.dZozyu');
  //   elements.forEach((element) => {
  //     element.removeEventListener('keydown', this.handleClick);
  //     element.removeEventListener('keypress', this.handleClick);
  //   });
  // }

  returnWebDevHtml2() {
    return (
      <div style={{ display: `flex`, justifyContent: `space-evenly`, flexWrap: 'wrap' }}>
        <div className={styles.asciiCard} style={{ color: this.props.sideBarColor2 }} data-toggle="modal" data-target="#WebDevModalCenter">
          &nbsp;_ _ _ _ _ _ _ _ <br />
          |     Skills    |<br />
          |    Overview   |<br />
          |---------------|<br />
          |&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faReact} />&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faJs} />&nbsp;
          <FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faDatabase} />&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faAngular} />&nbsp;
          <FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faGithub} /> |<br />
          |&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faPhp} />&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faLaravel} />
          &nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faJava} />&nbsp;
          <FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faMicrosoft} />&nbsp;<FontAwesomeIcon style={{ color: this.props.sideBarColor2 }} className='regularColorIcon' icon={faAws} />|<br />
          &nbsp;- - - - - - - -
        </div>

        <div className={styles.asciiCard} style={{ cursor: `pointer`, color: this.props.sideBarColor2 }}>
          <a href='https://github.com/users/RoyGinsel/projects/1/views/1' target='_blank'>
            &nbsp;_ _ _ _ _ _ _ _ _<br />
            | Website project |<br />
            |      Board      |<br />
            |-----------------|<br />
            |__|__|__|__|__|__|<br />
            |__|__|__|__|__|__|<br />
            &nbsp;- - - - - - - - -
          </a>
        </div>

        <div className={styles.asciiCard} style={{ cursor: `pointer`, color: this.props.sideBarColor2 }} onClick={() => alert("Coming soon!")} data-title="Request a feature on this website!">
          &nbsp;_ _ _ _ _ _ _ _ _<br />
          |        |        |<br />
          |        |        |<br />
          |  _ _ _ | _ _ _  |<br />
          |        |        |<br />
          |        |        |<br />
          &nbsp;- - - - - - - - -
        </div>
      </div>
    )
  }

  returnGameDevHtml2() {
    return (
      <div>
        {this.props.width >= 765 ? <><iframe data-v-8ab0f1f0="" allow="geolocation; microphone; camera; midi; encrypted-media;" src="https://i.simmer.io/@RoyGinsel/dmp-teaser-world" allowfullscreen="allowfullscreen"
          className={styles.responsiveFrame}></iframe>
          Controls: E: move up | Q: Move down | WASD: Up, Right, Down, Left | Mouse: look </> : <>
          View on desktop (╯°□°)╯︵ ┻━┻</>}
      </div>
    )
  }

  return3DModelHtml2() {
    return (
      <div>
        Coming soooon (╯°□°)╯︵ ┻━┻
      </div>
    )
  }

  render() {
    return (
      <div id="style-15" className={styles.TerminalContainer} style={{ height: "60vh", zoom: `1.1` }}>
        {this.state.focussed ?
          <button onClick={this.unfreezeScroll} style={{ position: `relative`, top:`33px`, right: `50px`, float: 'right', background: 'none', border: 'none' }} data-title="Unlock scroll position">
            <FontAwesomeIcon icon={faUnlockAlt}/>
          </button> : <button onClick={this.freezeScroll} style={{ position: `relative`, top:`33px`, right: `50px`, float: 'right', background: 'none', border: 'none' }} data-title="Lock scroll position">
          <FontAwesomeIcon icon={faLock} />
          </button>}
        <Terminal
          allowTabs={false}
          color={lightOrDark(this.props.sideBarColor) ?? 'green'}
          backgroundColor={"#ffffff00"}
          barColor={"#ffffff00"}
          style={{ overflow: 'hidden', fontWeight: "bold", fontSize: "1em", backgroundColor: this.props.sideBarColor + 'E6' }}
          commands={{
            'webdev': () => this.returnWebDevHtml2(),
            'gamedev': () => this.returnGameDevHtml2(),
            '3dmodeling': () => this.return3DModelHtml2(),
            'randomgif': (args, print, runCommand) => {
              http.get("https://api.giphy.com/v1/gifs/random?api_key=0UTRbFtkMxAplrohufYco5IY74U8hOes&tag=fail&rating=pg-13").then((x) => {
                try {
                  print(<iframe src={x.data.data.embed_url}></iframe>)
                } catch { }
              })
            },
          }}
          commandPassThrough={(cmd, print) => {
            if (cmd == "hooper") {
              var imageSource = this.hooperImages[this.getRandomInt(0, this.hooperImages.length - 1)]
              print(<img className={styles.hooperPictures} src={imageSource}></img>);
            }
          }}
          descriptions={{
            'webdev': 'Gives an overview of my skills as a full stack web developer',
            'gamedev': 'Gives an overview of my actions in making games',
            '3dmodeling': 'Gives my interest in 3D modeling',
            'randomgif': 'Gives a random graphic interchange format'
          }}
          startState='maximised'
          msg='Welcome to the skills section. You can find every skill I present to you here.'
          actionHandlers={{
            handleClose: () => {
            },
            handleMaximise: () => {
            },
            handleMinimise: () => {
            }
          }}
        />
      </div>
    );
  }
}
export default SkillsPage;